import React, { useContext } from "react";
import { AppContext } from "../../lib";
import "./AddAsAction.scss";

const hasKeys = (object) => Object.keys(object).length;

export default function AddAsAction() {
  const { dispatch, state } = useContext(AppContext);
  const {
    currentView,
    targetedCampaign,
    selectedProducts,
    selectedCollections,
    selectedSpecialConditions,
    campaigns,
  } = state;
  const hasSelection =
    hasKeys(selectedProducts) ||
    hasKeys(selectedCollections) ||
    hasKeys(selectedSpecialConditions);
  const viewingProducts = currentView === "products";
  const isActiveClass =
    targetedCampaign && hasSelection ? "active" : "disabled";
  const campaign = campaigns.index[targetedCampaign];

  const numOfSelectedProducts = Object.values(selectedProducts).length;
  const singleSelectedProduct = numOfSelectedProducts === 1;

  const numOfSelectedCollections = Object.values(selectedCollections).length;
  const singleSelectedCollection = numOfSelectedCollections === 1;

  const numOfSelectedConditions = Object.values(selectedSpecialConditions);
  const singleSelectedCondition = numOfSelectedConditions === 1;

  const addAsQualifyingBuy = () => {
    switch (currentView) {
      case "products": {
        if (!numOfSelectedProducts) return;
        const message = singleSelectedProduct
          ? `Please confirm \nAdding 1 product \nAs [QUALIFYING PRODUCT BUY] \nFor [CAMPAIGN: ${campaign.title}]`
          : `Please confirm \nAdding ${numOfSelectedProducts} products \nAs [QUALIFYING PRODUCT BUY] \nFor [CAMPAIGN: ${campaign.title}]`;
        const action = { type: "addSelectedAsQualifyingBuy" };
        const payload = { message, action };
        return dispatch({ type: "needsUserConfirmation", payload });
      }
      case "collections": {
        if (!numOfSelectedCollections) return;
        const message = singleSelectedCollection
          ? `Please confirm \nAdding 1 collection \nAs [QUALIFYING COLLLECTION BUY] \nFor [CAMPAIGN: ${campaign.title}]`
          : `Please confirm \nAdding ${numOfSelectedCollections} collections \nAs [QUALIFYING COLLLECTION BUY] \nFor [CAMPAIGN: ${campaign.title}]`;
        const action = { type: "addSelectedCollectionsAsQualifyingBuy" };
        const payload = { message, action };
        return dispatch({ type: "needsUserConfirmation", payload });
      }
      case "special conditions": {
        if (!numOfSelectedConditions) return;
        const message = singleSelectedCondition
          ? `Please confirm \nAdding 1 special condition \nAs [QUALIFYING SPECIAL CONDITION] \nFor [CAMPAIGN: ${campaign.title}]`
          : `Please confirm \nAdding ${numOfSelectedConditions} special conditions \nAs [QUALIFYING SPECIAL CONDITION] \nFor [CAMPAIGN: ${campaign.title}]`;
        const action = { type: "addSelectedSpecialConditionAsQualifyingBuy" };
        const payload = { message, action };
        return dispatch({ type: "needsUserConfirmation", payload });
      }
      default:
    }
  };

  const addAsFreeGift = () => {
    if (!numOfSelectedProducts) return;
    const message = singleSelectedProduct
      ? `Please confirm \nAdding 1 product \nAs [FREE GIFT] \nFor [CAMPAIGN: ${campaign.title}]`
      : `Please confirm \nAdding ${numOfSelectedProducts} products \nAs [FREE GIFT] \nFor [CAMPAIGN: ${campaign.title}]`;
    const action = { type: "addSelectedAsFreeGift" };
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  };

  return (
    <div className={`addAsAction ${isActiveClass}`}>
      <span className="addAsAction-label">Add As:</span>
      <div className="addAsAction-actions">
        <button className="addAsAction-action" onClick={addAsQualifyingBuy}>
          Qualifying Buy
        </button>
        {viewingProducts ? (
          <button className="addAsAction-action" onClick={addAsFreeGift}>
            Free Gift
          </button>
        ) : null}
      </div>
    </div>
  );
}
