import CheckForSave from "./CheckForSave";

export default function AddSelectedCollectionsAsQualifyingBuy(state, payload) {
  let { selectedCollections, targetedCampaign: campaignID, campaigns } = state;

  /** @type {ShopifyCollection[]} */
  const collectionList = Object.values(selectedCollections);
  if (!collectionList.length) return state;
  
  let campaign = campaigns.index[campaignID];
  let {customerBuysFromCollection} = campaign
  
  // Field may not be defined on older campaign objects
  customerBuysFromCollection = customerBuysFromCollection ? customerBuysFromCollection : []
  
  collectionList.forEach(collection => {
    const {id, title, handle} = collection
    const collectionLink = {id, title, handle}
    const alreadyAdded = customerBuysFromCollection.find((item) => item.id === id);
    if(!alreadyAdded){
      customerBuysFromCollection = customerBuysFromCollection 
        ? [...customerBuysFromCollection, collectionLink]
        : [collectionLink];
    }
  })


  campaign = { ...campaign, customerBuysFromCollection };

  state.campaigns.index[campaignID] = campaign;

  selectedCollections = {};
  return CheckForSave({ ...state, selectedCollections });
}
