import React, { useContext } from "react";
import { AppContext } from "../../../lib";
import { TrashIcon } from "../../../assets/svgs";
import "./CampaignCollectionItem.scss";

export default function CampaignCollectionItem({ collection, campaign }) {
  const { dispatch } = useContext(AppContext);
  const { title } = collection;
  const { archived, id: campaignID } = campaign;

  const askToUnarchive = () =>
    dispatch({
      type: "setActiveAlert",
      payload: {
        message:
          "This campaign is archived. Please unarchive this campaign to make this edit.",
      },
    });

  const removeCollection = () =>
    archived
      ? askToUnarchive()
      : dispatch({
          type: "removeBuyFromCollection",
          payload: { campaignID, collection },
        });

  return (
    <div className="campaignCollectionItem">
      <span className="campaignCollectionItem-label">{title}</span>
      <button
        className="campaignCollectionItem-remove"
        title={`Remove ${title}`}
        onClick={removeCollection}
      >
        <TrashIcon />
      </button>
    </div>
  );
}
