import TargetCampaignIndex from "./TargetCampaignIndex";
import CheckForSave from "./CheckForSave";
import { makeCampaign } from "../helpers";
import ConvertCampaign from "./ConvertCampaign";

export default function (state, payload) {
  const {rewardType} = payload
  let { campaigns } = state;
  console.log('createNewCampaign', {state, payload})

  let newCampaign = makeCampaign()
  newCampaign.title = `Campaign-${newCampaign.id}`;


  const { id } = newCampaign;

  if (campaigns) {
    campaigns.index[id] = newCampaign;
  } else {
    campaigns = { index: { [id]: newCampaign } };
  }

  state.campaigns = campaigns;

  state = TargetCampaignIndex(state, { id });
  state = CheckForSave(state, payload);

  setTimeout(() => {
    const element = document.getElementById(id);
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - window.innerHeight / 2;
    window.scrollTo({
      top: middle,
      left: 0,
      behavior: "smooth",
    });
  }, 200);

  if(rewardType){
    switch (rewardType) {
      case "Standard Gift":
        return { ...state };
      case "Tiered Gifts":
        return ConvertCampaign(state, {id: newCampaign.id, skipConfirm: true})
      default:
        return { ...state };
    }
  }
  
}
