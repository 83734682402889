import CheckForSave from "./CheckForSave";
import TargetCampaignIndex from "./TargetCampaignIndex";
import SetActiveCampaign from "./SetActiveCampaign";
import { toast } from "react-toastify";
export default function (state, payload) {
  let { campaigns } = state;
  let { id } = payload;
  const campaign = campaigns.index[id]
  
  // Show toast message if an active camaign
  if(campaign.active) toast.warn("This campaign is active. It will be unset if deleted")

  if(campaign.active) state = SetActiveCampaign(state, {id: ''})
  
  delete campaigns.index[id]
  
  state.campaigns = campaigns
  
  state = TargetCampaignIndex(state, {target: {id: null}})
  return CheckForSave({...state})
}
