import { headers, shopNameParam, apiWorker } from "..";
import { toast } from "react-toastify";

export default async function (campaignKeys, accessToken) {
  const { access_token } = accessToken;
  const { id, value } = campaignKeys;
  const payload = {
    metafield: {
      id,
      value,
      value_type: "string",
    },
  }

  // does the id being updated (activeCampaignMetafieldID) need to be multiples to match all active campaigns...can it?
  return await fetch(`${apiWorker}/shopMetafield?${shopNameParam}&metafield_id=${id}`, {
    method: "PUT",
    headers: headers(access_token),
    body: JSON.stringify(payload),
  })
    .then((res) => {
      if(!res.ok){
        if(res.status === 401){
          localStorage.removeItem('accessT')
          toast.warn("Your session has expired, please sign in again")
          setTimeout(()=>{
            window.location.reload()
          }, 3500)
        } else {
          toast.error("An error occured while saving, please refresh the app and save again")
        }
      }
      const json = res.json();
      return json;
    })
    .catch((err) => {
      toast.error(
        "An error occured while saving, please refresh the app and save again"
      );
    });
}
