import React, { useContext } from 'react'
import LoggedActivity from '../components/page/LoggedActivity'
import { AppContext } from '../lib'
import { Redirect } from 'react-router-dom'

export default function LogsRoute() {
  const {state} = useContext(AppContext)
  const {accessToken} = state
  return !!accessToken ? <LoggedActivity /> : <Redirect to="/login" />
}

