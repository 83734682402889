import CheckForSave from "./CheckForSave";

export default function ResetTierOfCampaign(state, payload) {
  const { campaigns } = state;
  const { id: campaignID, tierId } = payload;

  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];
  let { customerGets } = campaign

  for (const tier of campaign.tiers) {
    if (tier.id === tierId) {
      tier.minSpend = 0;
      tier.maxSpend = 0;
      // Remove existing gifts 
      if(tier.gift && customerGets) customerGets = customerGets.filter(({id}) => id !== tier.gift.id)
      
      tier.gift = null;
    }
  }

  campaign = { ...campaign, customerGets };
  campaigns.index[campaignID] = campaign;
  return CheckForSave({ ...state, campaigns });
}
