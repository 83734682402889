import { toast } from "react-toastify"
import { apiWorker, headers, shopNameParam } from ".."

export default async function AdjustBogoVariantInventoryForRemoval(product_id, accessToken){
  const endpoint = `${apiWorker}/adjustBogoVariantForRemoval?${shopNameParam}`
  const body = JSON.stringify({product_id}, null, 2)
  console.log('AdjustBogoVariantInventoryForRemoval', {product_id, endpoint} )

  return await fetch(endpoint,{
    method: 'POST',
    body,
    headers: headers(accessToken)
  })
  .then(res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=> window.location.reload(), 3500)
      } else {
        console.log('AdjustBogoVariantInventoryForRemoval:error', {res})
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = res.json()
    return json
  })
  .catch(err => {
    console.log('AdjustBogoVariantInventoryForRemoval:error', {err})
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}