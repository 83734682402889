import React from "react";
import CampaignCollectionItem from "../CampaignCollectionItem";
import CampaignProductItem from "../CampaignProductItem";
import CampaignSpecialBuyCondition from "../CampaignSpecialBuyCondition";

export default function StandardGiftConfigurationView({ campaign }) {
  const {
    customerBuys,
    customerBuysFromCollection,
    specialBuyConditions,
  } = campaign;

  return (
    <>
      <ProductBuyItems customerBuys={customerBuys} campaign={campaign} />
      <CollectionBuyItems
        customerBuysFromCollection={customerBuysFromCollection}
        campaign={campaign}
      />
      <SpecialBuyConditions
        specialBuyConditions={specialBuyConditions}
        campaign={campaign}
      />
    </>
  );
}

function ProductBuyItems({ customerBuys = [], campaign }) {
  return (
    <div className="campaign-productBuyItems">
      <p className="campaign-items-label">
        Qualifying Products: {customerBuys.length}
      </p>
      <div className="campaign-items">
        {customerBuys
          ? customerBuys.map((product) => (
              <CampaignProductItem
                key={`productItem-buyItem-${product.id}`}
                product={product}
                type={"buy"}
                campaign={campaign}
              />
            ))
          : null}
      </div>
    </div>
  );
}

function CollectionBuyItems({ customerBuysFromCollection = [], campaign }) {
  return (
    <div className="campaign-productBuyItems">
      <p className="campaign-items-label">
        Qualifying Collections: {customerBuysFromCollection.length}
      </p>
      <div className="campaign-items">
        {customerBuysFromCollection
          ? customerBuysFromCollection.map((collection) => (
              <CampaignCollectionItem
                key={`collection-collectionBuyItem-${collection.id}`}
                collection={collection}
                campaign={campaign}
              />
            ))
          : null}
      </div>
    </div>
  );
}

function SpecialBuyConditions({ campaign, specialBuyConditions = [] }) {
  return (
    <div className="campaign-productBuyItems">
      <p className="campaign-items-label">
        Qualifying Conditions: {specialBuyConditions.length}
      </p>
      <div className="campaign-items">
        {specialBuyConditions
          ? specialBuyConditions.map((specialCondition) => (
              <CampaignSpecialBuyCondition
                key={`specialCondition-specialBuyCondition-${specialCondition.id}`}
                specialCondition={specialCondition}
                campaign={campaign}
              />
            ))
          : null}
      </div>
    </div>
  );
}
