import CheckForSave from "./CheckForSave";

export default function UpdateCampaignTierMinSpend(state, payload){
    const { campaigns } = state;
    const { id: campaignID, tierId, price } = payload;
  
    /** @type {GWPCampaign} */
    let campaign = campaigns.index[campaignID];
  
    for(const tier of campaign.tiers){
      if(tier.id === tierId) tier.minSpend = price
    }
  
    campaigns.index[campaignID] = campaign;
  
    return CheckForSave({ ...state, campaigns });
}