import CheckForSave from "./CheckForSave";

export default function AddSelectedSpecialConditionsAsQualifyingBuy(state, payload){
    let { selectedSpecialConditions, targetedCampaign: campaignID, campaigns } = state;
    /** @type {GWPSpecialCondition[]} */
    const conditionList = Object.values(selectedSpecialConditions);
    if (!conditionList.length) return state;
    let campaign = campaigns.index[campaignID];
    let {specialBuyConditions} = campaign

    // Field may not be defined on older campaign objects
    specialBuyConditions = specialBuyConditions ? specialBuyConditions : []
    
    conditionList.forEach(collection => {
      const {id, title} = collection
      const conditionLink = {id, title}
      const alreadyAdded = specialBuyConditions.find((item) => item.id === id);
      if(!alreadyAdded){
        specialBuyConditions = specialBuyConditions 
          ? [...specialBuyConditions, conditionLink]
          : [conditionLink];
      }
    })
  
  
    campaign = { ...campaign, specialBuyConditions };
  
    state.campaigns.index[campaignID] = campaign;
  
    selectedSpecialConditions = {};
    return CheckForSave({...state, selectedSpecialConditions});
}