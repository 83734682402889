import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {

  /** @type {{startTime: {value: string, GTMTime: string}|null, id: String}} */
  const { startTime, id } = payload;
  const { campaigns } = state;

  /** @type {GWPCampaign} */
  let campaign = campaigns.index[id];
  
  // Don't do anything if the start date doesn't make sense
  const invalidStartDate = (startTime && campaign.endTime) && (new Date(campaign.endTime.GTMTime).getTime() <= new Date(startTime.GTMTime).getTime())

  if (invalidStartDate) {
    const message = `Start time is invalid (Must be ealier than ending time)`
    return SetActiveAlert(state, {message})
  }

  campaign = { ...campaign, startTime };
  
  // Handle setting the campaign type
  campaign = setCampaignType(campaign)
  
  // Handle setting the campaign status
  campaign = setCampaignStatus(campaign)

  campaign['type'] = startTime ? 'scheduled' : campaign['type'] 
  if(!startTime && !campaign.endTime) campaign['type'] = 'default'

  state.campaigns.index[id] = campaign

  return CheckForSave({...state})
}


/**
 * 
 * @param {GWPCampaign} campaign 
 */
function setCampaignType(campaign){
  const {startTime, endTime} = campaign

  campaign['type'] = startTime || endTime ? 'scheduled' : 'default'
  if(!startTime && !endTime) campaign['type'] = 'default'

  return campaign
}


/**
 * This wont be ran if the end time is expired
 * @param {GWPCampaign} campaign 
 */
 function setCampaignStatus(campaign){
  const {active, type, startTime, endTime} = campaign
  const currentGTMTime = new Date(Date.now()).toGMTString()

  console.log('setCampaignStatus', {campaign, currentGTMTime})

  if(type === 'scheduled'){
    if(!active){
      campaign['status'] = 'inactive'
    } else if(startTime && endTime && active){
      
      if(currentGTMTime >= startTime.GTMTime && currentGTMTime <= endTime.GTMTime){
        campaign['status'] = 'live'
      } else {
        campaign['status'] = 'scheduled'
      }

    } else {
      campaign['status'] = 'inactive'
    }

  } else {
    campaign['status'] = active ? 'active' : 'inactive'
  }

  return campaign
}