import React from 'react'
import { Link } from 'react-router-dom'
import { LogsIcon } from '../../assets/svgs'
import './LogsLink.scss'

export default function LogsLink() {
  return (
    <Link className="logsLink" to="/logs">
      <span className="logsLink-icon">
        <LogsIcon/>
      </span>
      <span className="logsLink-label">View Logs</span>
    </Link>
  )
}
