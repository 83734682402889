import React from "react";

export default function UnarchiveIcon() {
  return (
    <svg
      width="73"
      height="110"
      viewBox="0 0 73 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0719 66.958H67.8324V56.3592C67.8324 55.1913 66.8828 54.2395 65.7127 54.2395H6.35973C5.18963 54.2395 4.23998 55.1913 4.23998 56.3592V66.958H0.000488281V56.3592C0.000488281 52.8532 2.85367 50 6.35973 50H65.7127C69.2188 50 72.0719 52.8532 72.0719 56.3592V66.958Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4725 109.353H10.5985C7.09244 109.353 4.23926 106.5 4.23926 102.994V66.958H8.47875V102.994C8.47875 104.162 9.4284 105.113 10.5985 105.113H61.4725C62.6426 105.113 63.5922 104.162 63.5922 102.994V66.958H67.8317V102.994C67.8317 106.5 64.9785 109.353 61.4725 109.353Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5147 79.6765H27.5568C26.3845 79.6765 25.437 78.7269 25.437 77.5568C25.437 76.3867 26.3845 75.437 27.5568 75.437H44.5147C45.687 75.437 46.6345 76.3867 46.6345 77.5568C46.6345 78.7269 45.687 79.6765 44.5147 79.6765Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 62.7185H72.0714V66.958H0V62.7185Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9394 0.93934C35.5252 0.353553 36.4749 0.353553 37.0607 0.93934L46.6066 10.4853C47.1924 11.0711 47.1924 12.0208 46.6066 12.6066C46.0209 13.1924 45.0711 13.1924 44.4853 12.6066L37.5 5.62132V41H34.5V5.62132L27.5148 12.6066C26.929 13.1924 25.9792 13.1924 25.3934 12.6066C24.8077 12.0208 24.8077 11.0711 25.3934 10.4853L34.9394 0.93934Z"
        fill="currentColor"
      />
    </svg>
  );
}
