import React, { useReducer, useEffect } from "react";
import "./styles/App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { AppContext } from "./lib";
import AppReducer from "./lib/reducer";
import HeaderNavigation from "./components/layout/HeaderNavigation";
import { MainRoute, LoginRoute, AuthRoute, LogsRoute } from "./routes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";

toast.configure({ autoClose: 3500, draggable: false });

let specialConditions = [
  { id: "anyProductInStore", title: "Any Product in Store" },
  { id: "tieredGifts", title: "Tiered Gifts",
    tiers: [
      { minSpend: 0, maxSpend: 0, gift: null }
    ]
  }
]
specialConditions = { list: [...specialConditions], index: {} }
specialConditions.list.forEach(specialCondition => {
  specialConditions.index[specialCondition.id] = specialCondition
})

const defaultState = {
  currentView: "products",
  selectedProducts: {},
  selectedCollections: {},
  selectedSpecialConditions: {},
  specialConditions,
  needsConfirmation: false,
  activeAlert: false
};

function App() {
  const [state, dispatch] = useReducer(AppReducer, defaultState);
  const { ready } = state;

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem("accessT"));
    dispatch({ type: "assignAccessToken", payload: accessToken });
    dispatch({ type: "readyApp" });
  }, []);

  return ready ? (
    <Router>
      <HeaderNavigation />
      <AppContext.Provider value={{ state, dispatch }}>
        <Switch>
          <Route path="/main" exact render={() => <MainRoute />} />
          <Route path="/logs" exact render={() => <LogsRoute />} />
          <Route path="/login" exact render={() => <LoginRoute />} />
          <Route path="/auth" render={() => <AuthRoute />} />
          <Route path="" render={() => <Redirect to="/main" />} />
        </Switch>
      </AppContext.Provider>
    </Router>
  ) : (
    <span>PLEASE WAIT...</span>
  );
}

export default App;
