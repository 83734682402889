import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { DownCaret } from "../../assets/svgs";
import { GetLogs } from "../../lib/shopifyAPI";
import { LogItem } from "../Log";

import './LoggedActivity.scss'

export default function LoggedActivity() {
  const [logs, setLogs] = useState([])


  async function fetchLogs() {
    const logData = await GetLogs()
    setLogs(logData)
  }
  
  useEffect(() => {
    fetchLogs()
  },[])

  return (
    <div className="loggedActivity">
      <div className="loggedActivity-header">
        <h1 className="loggedActivity-subheading">Scheduled Campaigns Log Activity</h1>
        <Link className="loggedActivity-goback" to="/">
          <span className="loggedActivity-goback-icon">
            <DownCaret />
          </span>
          Go Back
        </Link>
      </div>
      <p>Logged events performed during the background task running to publish/unpublish scheduled campaigns</p>
      {logs
        ? logs.map((log) => <LogItem log={log} />)
        : null
        }
    </div>
  );
}
