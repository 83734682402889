import { makeCampaignTier } from "../helpers";
import CheckForSave from "./CheckForSave";

export default function ConvertCampaign(state, payload) {
  const { campaigns } = state;
  const { id: campaignID } = payload;
  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];
  const { rewardType } = campaign;
  const newRewardType =
    !rewardType || rewardType === "Standard Gift"
      ? "Tiered Gifts"
      : "Standard Gift";

  // Clear all product and collection settings
  campaign.customerBuys = [];
  campaign.customerBuysFromCollection = [];
  campaign.specialBuyConditions = [];
  campaign.customerGets = [];

  if(newRewardType === "Tiered Gifts"){
    // Push the special condition
    // This will signal client side should inspect the campaigns "tiers" fields
    const tieredGiftsSpecialConditions = {
      id: "tieredGifts",
      title: "Tiered Gifts",
    };
    campaign.specialBuyConditions = [tieredGiftsSpecialConditions];
  
    // Create the initial tier value in tiers array
    /** @type {GWPCampaignTieredGiftsTier} */
    const tier = makeCampaignTier();
    campaign.tiers = [tier];
  }

  // Set new campaign rewardType
  campaign.rewardType = newRewardType;
  campaigns.index[campaignID] = campaign;

  return CheckForSave({ ...state, campaigns });
}
