import { headers, shopNameParam, apiWorker } from "..";
import { toast } from "react-toastify";

export default async function (activeCampaign, accessToken) {
  const { access_token } = accessToken;
  const {metafieldID, value} = activeCampaign;
  let payload = {};
  // let campaigns = value.split(',');

  payload = {
    metafield: {
      metafieldID,
      value,
      value_type: "string",
    },
  }
  
  return await fetch(
    `${apiWorker}/shopMetafield?${shopNameParam}&metafield_id=${metafieldID}`,
    {
      method: "PUT",
      headers: headers(access_token),
      body: JSON.stringify(payload),
    }
  )
    .then((res) => {
      if(!res.ok){
        if(res.status === 401){
          localStorage.removeItem('accessT')
          toast.warn("Your session has expired, please sign in again")
          setTimeout(()=>{
            window.location.reload()
          }, 3500)
        } else {
          toast.error("An error occured while saving, please refresh the app and save again")
        }
      }
      const json = res.json();
      return json;
    })
    .catch((err) => {
      toast.error(
        "An error occured while saving, please refresh the app and save again"
      );
    });
}
