import { getHeaders, apiWorker, shopName } from "..";
import { toast } from "react-toastify";

export default async function GetAllProducts(accessToken) {
  const {access_token} = accessToken
  const res = await fetch(`${apiWorker}/allProducts?shop=${shopName}`, {
    method: 'GET',
    headers:getHeaders(access_token)
  })

  if(!res.ok){
    if(res.status === 401){
      localStorage.removeItem('accessT')
      toast.warn("Your session has expired, please sign in again")
      setTimeout(()=> window.location.reload(), 3500)
    }
  }

  const {products} = await res.json()
  console.log('products', products)
  return products
}