import React, { useContext } from "react";
import "./DiscountCampaign.scss";
import { AppContext } from "../../lib";
import {
  CloseIcon,
  CycleIcon,
  TrashIcon,
  DuplicateIcon,
  ArchiveIcon,
  UnarchiveIcon,
} from "../../assets/svgs";
import CampaignProductItem from "./CampaignProductItem";
import DiscountCampaignDate from "./DiscountCampaignDate";
import TieredGiftConfigurationView from "./TieredGiftConfigurationView";
import StandardGiftConfigurationView from "./StandardGiftConfigurationView";

export default React.memo(function DiscountCampaign({ campaignID }) {
  const { dispatch, state } = useContext(AppContext);
  const { targetedCampaign, campaigns } = state;
  const { index } = campaigns;
  const campaign = index[campaignID];
  const { id, info, active, rewardType, archived } = campaign;
  const currentTarget = targetedCampaign === id;
  const currentTargetClass = currentTarget ? "targeted" : "";
  const archivedClass = archived ? "archived" : "";


  const askToUnarchive = () => dispatch({ type: "setActiveAlert", payload: {message: 'This campaign is archived. Please unarchive this campaign to make this edit.'} });

  const handleCampaignClick = (evt) => {
    console.log("handleCampaignClick");
    dispatch({ type: "targetCampaignIndex", payload: { id } });
  };

  const handleCampaignClose = (evt) => {
    console.log("handleCampaignClose");
    evt.stopPropagation();
    dispatch({ type: "targetCampaignIndex", payload: { id: null } });
  };

  const handleTitleChange = ({ target }) => {
    if(archived) return askToUnarchive()
    const { value } = target;
    const payload = { value, campaignID };
    dispatch({ type: "updateCampaignTitle", payload });
  };

  const handleInfoChange = ({ target }) => {
    if(archived) return askToUnarchive()

    const { value } = target;
    const payload = { value, campaignID };
    dispatch({ type: "updateCampaignInfo", payload });
  };

  const handleStatusChange = () => {
    if(archived) return askToUnarchive()
    const action = { type: "setActiveCampaign", payload: { id, active } };
    const message = !active
      ? `Are you sure you want to activate this campaign? Please remember to save.`
      : `Are you sure you would like to deactive this campaign?`;
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  };

  const handleCampaignStartDateChange = ({ startTime }) => {
    if(archived) return askToUnarchive()
    if(startTime && !startTime.value) startTime = null
    dispatch({ type: "setStartTime", payload: { startTime, id } });
  };

  const handleCampaignEndDateChange = ({ endTime }) => {
    if(archived) return askToUnarchive()
    if(endTime && !endTime.value) endTime = null
    dispatch({ type: "setEndTime", payload: { endTime, id } });
  };

  const handleCampaignConvert = () => {
    if(archived) return askToUnarchive()

    const newRewardType =
      !rewardType || rewardType === "Standard Gift"
        ? "Tiered Gifts"
        : "Standard Gift";
    const message = `Please confirm this campaign conversion\nCAMPAIGN: [${campaign.title}]\nTO REWARD TYPE: [${newRewardType}] Campaign?`;
    const action = { type: "convertCampaign", payload: { id } };
    const payload = { message, action };
    return dispatch({ type: "needsUserConfirmation", payload });
  };

  return (
    <div
      className={`campaign ${currentTargetClass} ${archivedClass}`}
      id={id}
      onClick={handleCampaignClick}
    >
      <CampaignTopbar campaign={campaign} onClose={handleCampaignClose} />
      <CampaignStatus campaign={campaign} onClick={handleStatusChange} />
      <CampaignActions campaign={campaign} />
      <CampaignTitle
        campaign={campaign}
        currentTarget={currentTarget}
        onChange={handleTitleChange}
      />
      <div className="campaign-items-container">
        <ProductRewardTypeView campaign={campaign} />
      </div>

      <hr />
      <div className="campaign-items-container">
        <ProductGiftsView campaign={campaign} />
      </div>
      <div className="campaign-details">
        <label>
          Short campaign description (Do not use double quotes):
          <br />
          <textarea
            className="campaign-info"
            value={info}
            onChange={handleInfoChange}
          ></textarea>
        </label>

        <DiscountCampaignDate
          campaign={campaign}
          onStartDateChange={handleCampaignStartDateChange}
          onEndDateChange={handleCampaignEndDateChange}
        />

        <div className="campaign-options">
          <button
            className="btn campaign-convert"
            onClick={handleCampaignConvert}
          >
            <CycleIcon />
            <span>
              Convert to{" "}
              {!rewardType || rewardType === "Standard Gift"
                ? "Tiered Gifts"
                : "Standard Gift"}{" "}
              Campaign
            </span>
          </button>
        </div>
      </div>
      <CampaignWarnings campaign={campaign} />
    </div>
  );
});

/**
 * Topbar
 */
function CampaignTopbar({ campaign, onClose: handleClose }) {
  return (
    <div className="campaign-topbar">
      <span className="campaign-close btn" onClick={handleClose}>
        <CloseIcon />
      </span>
      <CampaignType campaign={campaign} />
    </div>
  );
}

/**
 * A view switch basedd on the campaigns type
 */
function ProductRewardTypeView({ campaign }) {
  const { rewardType } = campaign;

  switch (rewardType) {
    case "Standard Gift":
      return <StandardGiftConfigurationView campaign={campaign} />;
    case "Tiered Gifts":
      return <TieredGiftConfigurationView campaign={campaign} />;
    default:
      return <StandardGiftConfigurationView campaign={campaign} />;
  }
}

/**
 * Renders the free gift line items
 */
function ProductGiftsView({ campaign }) {
  const { rewardType, customerGets } = campaign;

  switch (rewardType) {
    case "Tiered Gifts":
      return null;
    default:
      return (
        <>
          <p className="campaign-items-label">
            Free Gifts: {customerGets.length}
          </p>
          <div className="campaign-items">
            {customerGets
              ? customerGets.map((product) => (
                  <CampaignProductItem
                    key={`productItem-getItem-${product.id}`}
                    product={product}
                    type={"get"}
                    campaign={campaign}
                  />
                ))
              : null}
          </div>
        </>
      );
  }
}

/**
 * Displays and can update campaign title
 */
function CampaignTitle({
  campaign,
  currentTarget: targeted,
  onChange: handleChange,
}) {
  const { title } = campaign;

  return targeted ? (
    <input
      placeholder="Campaign Title (Do not use double quotes)"
      value={title}
      onChange={handleChange}
      className="campaign-title"
    />
  ) : (
    <h3 className="campaign-title">{title}</h3>
  );
}

/**
 * Displays the type of camopaign
 */
function CampaignType({ campaign }) {
  let { type, rewardType } = campaign || {};
  type = type ? type : "default";

  return (
    <span className="campaign-type">
      Type: {type.charAt(0).toUpperCase() + type.slice(1)} |{" "}
      {rewardType || "Standard Gift"}
    </span>
  );
}

/**
 * Shows the active status of a campaign
 */
function CampaignStatus({ campaign, onClick: handleClick }) {
  const { active, type, status, archived } = campaign || {};
  const activeClass = active ? "active" : "";
  const scheduledClass = active && type === "scheduled" ? "scheduled" : "";
  const isLive =
    status === "live" || (!type && active) || (type === "default" && active);
  const liveClass = isLive ? "live" : "";
  const isExpired = status === "expired";
  const expiredClass = isExpired ? "expired" : "";
  let text = "";

  if (archived) {
    text = "Archived";
  } else if (active) {
    if (isLive) {
      text = "Live";
    } else {
      if (type === "scheduled") text = "Scheduled";
      else text = "Active";
    }
  } else {
    if (status === "expired") text = "Expired";
    else text = "Inactive";
  }

  return campaign ? (
    <span
      className={`campaign-status ${activeClass} ${scheduledClass} ${liveClass} ${expiredClass}`}
      onClick={handleClick}
    >
      {text}
    </span>
  ) : null;
}

function CampaignActions({ campaign }) {
  const { dispatch } = useContext(AppContext);
  const { id, archived } = campaign;

  function handleDeleteCampaign() {
    console.log("handleDeleteCampaign", { campaign });
    const action = { type: "removeCampaign", payload: { id } };
    const message =
      "Are you sure you would like to delete this campaign? This cannot be undone after saving.";
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  }

  

  function handleDuplicateCampaign() {
    console.log("handleDuplicateCampaign", { campaign });
    const action = { type: "duplicateCampaign", payload: { id } };
    const message = `Are you about to duplicate this campaign?`;
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  }

  function handleArchiveCampaign() {
    console.log("handleArchiveCampaign", { campaign });
    const action = { type: "archiveCampaign", payload: campaign };
    const message = `Are you sure you want to archive this campaign? Please remember to save.`;
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  }

  function handleUnarchiveCampaign() {
    console.log("handleUnarchiveCampaign", { campaign });
    const action = { type: "unarchiveCampaign", payload: campaign };
    const message = `Are you sure you want to unarchive this campaign? Please remember to save.`;
    const payload = { message, action };
    dispatch({ type: "needsUserConfirmation", payload });
  }

  return !archived ? (
    <div className="campaign-actions">
      <button
        className="campaign-action trash"
        title="Delete"
        onClick={handleDeleteCampaign}
      >
        <span className="campaign-action-icon">
          <TrashIcon />
        </span>
        <span className="campaign-action-label">Delete</span>
      </button>
      <button
        className="campaign-action duplicate"
        title="Duplicate"
        onClick={handleDuplicateCampaign}
      >
        <span className="campaign-action-icon">
          <DuplicateIcon />
        </span>
        <span className="campaign-action-label">Duplicate</span>
      </button>
      <button
        className="campaign-action archive"
        title="Archive"
        onClick={handleArchiveCampaign}
      >
        <span className="campaign-action-icon">
          <ArchiveIcon />
        </span>
        <span className="campaign-action-label">Archive</span>
      </button>
    </div>
  ) : (
    <div className="campaign-actions">
      <button
        className="campaign-action unarchive"
        title="Unarchive"
        onClick={handleUnarchiveCampaign}
      >
        <span className="campaign-action-icon unarchive">
          <UnarchiveIcon />
        </span>
        <span className="campaign-action-label">Archive</span>
      </button>
    </div>
  );
}

/**
 * Shows hints and warning about the campaign
 */
function CampaignWarnings({ campaign }) {
  const {
    tiers,
    customerGets,
    customerBuys,
    customerBuysFromCollection,
    specialBuyConditions,
    rewardType,
  } = campaign;
  const isPriceTierCampaign = rewardType === "Tiered Gifts";
  const incompleteTierSettngs = isPriceTierCampaign && !tiers.length;
  const hasIncompleteTier =
    isPriceTierCampaign &&
    tiers &&
    tiers.find(
      ({ minSpend, maxSpend, gift }) => !minSpend || !maxSpend || !gift
    );
  const hasNoFreeGift = !customerGets.length;
  const hasNoQualifier =
    !customerBuys.length &&
    customerBuysFromCollection &&
    !customerBuysFromCollection.length &&
    specialBuyConditions &&
    !specialBuyConditions.length;

  return (
    <ul className="campaign-warnings">
      {incompleteTierSettngs ? <li>Incomplete Tier Settings</li> : null}

      {hasIncompleteTier ? <li>Incomplete Tier</li> : null}

      {hasNoFreeGift ? <li>No Free Gift Offered</li> : null}

      {hasNoQualifier ? <li>Has No Qualifier</li> : null}
    </ul>
  );
}
