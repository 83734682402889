import React from 'react'
import { Reset, TrashIcon } from '../../../../assets/svgs';

export default function PriceTierField({
    campaign,
    tier,
    tierIndex,
    onTierRemoval: handleTierRemoval,
    onTierReset: handleTierReset,
    onMinSpendChange: handleMinSpendChange,
    onMaxSpendChange: handleMaxSpendChange,
    onGiftInspect: handleGiftInspect,
  }) {
    const { minSpend, maxSpend, gift, id: tierId } = tier;
    const { id } = campaign;
  
    const handleMinSpendValue = ({ target: { value: price } }) =>
      handleMinSpendChange({ tierId, price });
  
    const handleMaxSpendValue = ({ target: { value: price } }) =>
      handleMaxSpendChange({ tierId, price });
  
    return (
      <div className="campaign-priceTier">
        <div className="campaign-priceTier-input-wrapper">
          <span className="campaign-priceTier-input-label">Min Spend</span>
          <input
            className="campaign-priceTier-input minSpend"
            value={parseFloat(minSpend)}
            defaultValue={parseFloat(minSpend)}
            type="number"
            min="0.00"
            max="10000.00"
            step="0.01"
            onChange={handleMinSpendValue}
          />
        </div>
        <div className="campaign-priceTier-input-wrapper">
          <span className="campaign-priceTier-input-label">Max Spend</span>
  
          <input
            className="campaign-priceTier-input maxSpend"
            defaultValue={maxSpend}
            value={maxSpend}
            type="number"
            min="0.00"
            max="10000.00"
            step="0.01"
            onChange={handleMaxSpendValue}
          />
        </div>
        <div className="campaign-priceTier-input-wrapper gift-input">
          <span className="campaign-priceTier-input-label">Gift</span>
          <button
            className="campaign-priceTier-gift"
            onClick={() => handleGiftInspect({ tier, tierIndex })}
            title={gift ? gift.title : ""}
          >
            {gift ? gift.title : ""}
          </button>
        </div>
  
        <div className="campaign-priceTier-actions">
          <span
            className="campaign-priceTier-reset"
            label="Reset"
            onClick={() => handleTierReset({ id, tierId })}
          >
            <Reset />
          </span>
  
          <button
            className="campaign-priceTier-removal"
            label="Remove"
            onClick={() => handleTierRemoval({ id, tierId })}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    );
  }