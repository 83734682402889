import React, { useContext, useState } from "react";
import { AppContext } from "../../../../lib";
import TieredGiftPopupTopbar from "../TieredGiftPopupTopbar";
import TieredGiftItem from "../TieredGiftItem";
import Loader from "../../../layout/Loader";

export default function ProductSelectionPopup({
    tier,
    campaign,
    onGiftSelection: handleGiftSelection,
    onSelectionClose: handleSelectionClose,
    onGiftReset: handleGiftReset,
  }) {
    const { state } = useContext(AppContext);
    const { products } = state;
    const [search, setSearch] = useState("");
    const filteredList = products
      ? Object.values(products.index).filter(
          (product) =>
            product.title &&
            product.title.toLowerCase().includes(search.toLowerCase())
        )
      : [];
    const handleSearchInputChange = ({ target: { value } }) => setSearch(value);

    return (
      <div className="popup">
        <div className="popup-background" onClick={handleSelectionClose}></div>
        <div className="popup-container">
          <TieredGiftPopupTopbar
            tier={tier}
            campaign={campaign}
            onGiftReset={handleGiftReset}
          />
          <h3 className="popup-heading">Tier {tier.index}: Assignable Products</h3>
          <input
            className="popup-search"
            type="text"
            placeholder="Search Products"
            value={search}
            onChange={handleSearchInputChange}
          />
          <div className="popup-index-fadeEdge"></div>
          <div className="popup-index">
            {products ? (
              filteredList.map((product) => (
                <TieredGiftItem
                  key={`product-${product.id}`}
                  tier={tier}
                  product={product}
                  onClick={handleGiftSelection}
                />
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  }
  