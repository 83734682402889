import React, { useContext } from "react";
import { AppContext } from "../../../lib";
import "../modal.scss";
import "./AppConfirm.scss";

export default function AppConfirm() {
  const { state, dispatch } = useContext(AppContext);
  const { needsConfirmation } = state;
  const { action, message } = needsConfirmation;
  const close = () =>
    dispatch({ type: "needsUserConfirmation", payload: null });

  const confirm = () => {
    if (needsConfirmation.action) dispatch(action);
    close();
  };

  return (
    <div className="modal appConfirm">
      <div className="modal-background" onClick={close} />
      <div className="modal-container">
        <p className="modal-text">{message}</p>
        <div className="modal-actions">
          <button className="modal-action" onClick={close}>
            Cancel
          </button>
          <button className="modal-action confirm" onClick={confirm}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
