import React from "react";

export default function DownCaret() {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.515015 1.465L9.00001 9.95L17.485 1.465L16.071 0.0499973L9.00001 7.122L1.92901 0.0499973L0.515015 1.465Z"
        fill="currentColor"
      />
    </svg>
  );
}
