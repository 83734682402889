import { headers, apiWorker, shopNameParam } from "..";
import { toast } from "react-toastify";
import { ManageAndAdjustBogoVariantInventory } from ".";

export default async function(product_id, accessToken, isBogo){
  const {access_token} = accessToken

  // GET: Product Variants
  const {variants} = await getProductVariants(product_id, access_token)
  
  // Check for Free Gift Product Variant
  const hasFreeGiftVariant = checkForFreeGiftVariant(variants)
  const shouldManageInventory = hasFreeGiftVariant && isBogo ? !hasFreeGiftVariant.inventory_item_id : null

  //  POST: Free Gift Product Variant if(!hasFreeGiftVariant) 
  if(!hasFreeGiftVariant) await createFreeGiftVariant(product_id, access_token, getDefaultVariantSKU(variants), isBogo)
  else if(hasFreeGiftVariant && shouldManageInventory) {
    await ManageAndAdjustBogoVariantInventory(product_id, access_token)
  }
  return
}


async function getProductVariants(product_id, access_token){
  return await fetch(`${apiWorker}/productVariants?${shopNameParam}&product_id=${product_id}`,{
    method: 'GET',
    headers: headers(access_token)
  })
  .then(res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=>{
          window.location.reload()
        }, 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = res.json()
    return json
  })
  .catch(err => {
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}


function checkForFreeGiftVariant(variants){
  return variants.find(({title}) => title === "FREEGIFT")
}

function getDefaultVariantSKU(variants) {
  if (variants.length === 1) {
    return variants[0].sku
  } else {
    return null
  }
}

async function createFreeGiftVariant(product_id, access_token, sku, isBogo){
  const freeGiftVariantPayload = {
    "variant": {
      "option1": "FREEGIFT",
      "price": "0.00",
      "inventory_management": isBogo ? 'shopify' : null, // Manage inventory of BOGO products
      sku
    }
  }

  return await fetch(`${apiWorker}/productVariant?${shopNameParam}&product_id=${product_id}`,{
    method: 'POST',
    headers: headers(access_token),
    body: JSON.stringify(freeGiftVariantPayload)
  })
  .then(async res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=> window.location.reload(), 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = await res.json()

    if(isBogo){
      console.log('[BOGO] SPLITTING DEFAULT VARIANT INVENTORY WITH FREE GIFT')
      const {variant} = json
      const {product_id} = variant 
      await ManageAndAdjustBogoVariantInventory(product_id, access_token)
      .then(res => console.log('SPLITTING BOGO RESULT', {res}))
      .catch(err => console.log('ERROR SPLITTING BOGO', {err}))
    }

    return json
  })
  .catch(err => {
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}