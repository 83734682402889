import React from "react";

export default function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 22C7.95361 22.0024 4.30455 19.5659 2.75572 15.8276C1.20689 12.0893 2.0636 7.78606 4.92606 4.92598C7.45335 2.39868 11.137 1.41166 14.5893 2.33672C18.0417 3.26177 20.7383 5.95836 21.6633 9.41072C22.5884 12.8631 21.6014 16.5467 19.0741 19.074C17.2015 20.955 14.6542 22.0086 12.0001 22ZM4.00006 12.172C4.04737 16.5732 7.64116 20.1095 12.0426 20.086C16.444 20.0622 19.9996 16.4875 19.9996 12.086C19.9996 7.68449 16.444 4.10975 12.0426 4.08598C7.64116 4.06244 4.04737 7.59874 4.00006 12V12.172ZM9.40906 16L8.00006 14.59L10.5901 12L8.00006 9.40998L9.41006 7.99998L12.0001 10.59L14.5901 7.99998L16.0001 9.40998L13.4101 12L16.0001 14.59L14.5911 16L12.0001 13.41L9.41006 16H9.40906Z"
        fill="currentColor"
      />
    </svg>
  );
}
