import React, { useContext } from 'react'
import { apiWorker, AppContext } from '../lib'
import { Redirect } from 'react-router-dom'

const isSafari = navigator.userAgent.indexOf("Safari") > -1;
export default function LoginRoute() {
  const {state} = useContext(AppContext)
  const {accessToken} = state
  const authorizeURL = `${apiWorker}/authorize?shop=${process.env.REACT_APP_SHOPIFY_STORE_NAME}&redirect=${process.env.REACT_APP_SHOPIFY_APP_URL}/auth/authorized`
  
  return !!accessToken ?  <Redirect to='/main' /> :  (
    <div className="page LoginPage">
      <a className="loginButton" href={authorizeURL} target={isSafari ? '_blank' : '_self'} rel="noopener noreferrer">LOGIN</a>
    </div>
  )

}

