import React, { useContext } from "react";
import { AppContext } from "../../../../lib";
import "./TieredGiftItem.scss";

export default function ProductIndexItem({
  tier,
  product,
  onClick: handleClick,
}) {
  const { state } = useContext(AppContext);
  const { selectedProducts } = state;
  const selected = selectedProducts[product.id];
  const selectedClass = selected ? "selected" : "";
  const handleProductClick = () => handleClick({ tierId: tier.id, product });

  return (
    <div
      className={`product-index-item tieredGift-item ${selectedClass}`}
      id={product.id}
      onClick={handleProductClick}
    >
      <div>
        <p className="product-index-item-id">Product ID: {product.id}</p>
        <p className="product-index-item-title">{product.title}</p>
        <p className="product-index-item-inventory">
          Qty: {product.variants[0].inventory_quantity}
        </p>
      </div>
    </div>
  );
}
