import React, { useContext, useState } from "react";
import "./SaveButton.scss";
import { AppContext } from "../../lib";
import { UpdateCampaignData } from "../../lib/shopifyAPI";

export default function SaveButton() {
  const { dispatch, state } = useContext(AppContext);
  const {campaigns, accessToken} = state
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = async () => {
    setIsSaving(true);
    console.log('campaigns', campaigns)
    const data = await UpdateCampaignData(campaigns, accessToken)
    dispatch({ type: "saveCampaigns" });
    setIsSaving(false);
    dispatch({type: 'hydrateState', payload: data})
  };
  return (
    <button className={`saveBtn ${isSaving ? 'saving' : ''}`} onClick={handleSave} disabled={isSaving}>
      {isSaving ? "SAVING" : "SAVE"}
    </button>
  );
}
