import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {

  /** @type {{endTime: {value: string, GTMTime: string}|null, id: String}} */
  const { endTime, id } = payload;
  const { campaigns } = state;

  /** @type {GWPCampaign} */
  let campaign = campaigns.index[id];
  
  // Don't do anything if the end date doesn't make sense
  const invalidEndDate = (campaign.startTime && endTime) && new Date(campaign.startTime.GTMTime).getTime() >= new Date(endTime.GTMTime).getTime()
  if (invalidEndDate) {
    const message = `Ending time is invalid (Must be later than starting time)`
    return {...SetActiveAlert(state, {message})}
  }
  
  const currentGTMTime = new Date(Date.now()).toGMTString()
  const endDateExpired = endTime && (new Date(currentGTMTime).getTime() >= new Date(endTime.GTMTime).getTime())

  console.log('SetCampaignEndTime',{endTime, currentGTMTime, endDateExpired})
  if(endDateExpired){
    const message = `The ending time is expired. Please deactivate the campaign instead if that is desired`
    return SetActiveAlert(state, {message})
  }

  // Set any valid endTime
  campaign = { ...campaign, endTime };

  // Handle setting the campaign type
  campaign = setCampaignType(campaign)
  
  // Handle setting the campaign status
  campaign = setCampaignStatus(campaign)

  // Update the index of campaigns
  state.campaigns.index[id] = campaign
  
  // Check for save
  return CheckForSave({...state})
}


/**
 * 
 * @param {GWPCampaign} campaign 
 */
function setCampaignType(campaign){
  const {endTime, startTime} = campaign
  if(endTime){
    campaign['type'] = endTime ? 'scheduled' : campaign['type'] 
  } else if (!endTime && !startTime){
    campaign['type'] = 'default'
  } else campaign['type'] = 'scheduled'

  return campaign
}


/**
 * This wont be ran if the end time is expired
 * @param {GWPCampaign} campaign 
 */
function setCampaignStatus(campaign){
  const {status, type, active} = campaign

  if(!status || status === 'expired'){

    if(type === 'scheduled'){
      campaign['status'] = 'scheduled'
    } else {
      campaign['status'] = active ? 'active' : 'inactive'
    }
  }

  return campaign
}