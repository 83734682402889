import { getHeaders, apiWorker, shopName } from "..";
import { toast } from "react-toastify";

export default async function (accessToken) {
  const { access_token } = accessToken;

  const res = await fetch(`${apiWorker}/shopMetafields?shop=${shopName}`, {
    method: "GET",
    headers: getHeaders(access_token),
  });

  if (!res.ok) {
    if (res.status === 401) {
      localStorage.removeItem("accessT");
      toast.warn("Your session has expired, please sign in again");
      setTimeout(() => window.location.reload(), 3500);
      return null
    } else {
      toast.error("An error occured fetching shop info");
      return null
    }
  }

  const data = await res.json();
  if (data.ok !== undefined && !data.ok) {
    if (data.status && data.status === 401) {
      localStorage.removeItem("accessT");
      toast.warn("Your session has expired, please sign in again");
      setTimeout(() => window.location.reload(), 3500);
      return null
    } else {
      toast.error("An error occured fetching shop info");
      return null
    }
  }

  console.log('SHOP METAFIELDS', data)
  
  return data;
}
