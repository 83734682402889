import SetCampaignKeys from "./SetCampaignKeys";
import DeleteCampaignKey from "./DeleteCampaignKey";
import SetActiveCampaignKey from "./SetActiveCampaignKey";
import SetCampaignMetafield from "./SetCampaignMetafield";
import UpdateShopifyBuyItemsCollection from "./UpdateShopifyBuyItemsCollection";
import UpdateShopifyGetItemsCollection from "./UpdateShopifyGetItemsCollection";
import { toast } from "react-toastify";

export default async function UpdateCampaignData(campaignData, accessToken) {
  let {
    index: campaigns,
    active,
    originKeys: storedKeys,
    campaignMetafieldsIndex,
    activeCampaignMetafieldID,
    campaignKeysID,
  } = campaignData;

  const bogoCampaignSet = findBogoCampaigns(campaigns)

  // Keep a pointer set of the campaign keys
  const campaignKeySet = {};

  // Adds stored keys to campaignKeySet flagged as already stored
  storedKeys
    .split(",")
    .forEach((key) => (campaignKeySet[key] = { stored: true }));

  // Set all the keys that we want to keep saved (existing and new)
  Object.keys(campaigns).forEach((key) => {
    campaignKeySet[key] = { saving: true };
  });

  // Map out the keys that are conditioned for removal
  const removedKeys = Object.entries(campaignKeySet)
  .filter(([_, value]) => value.stored && !value.saving) // Filter out keys that where stored but not mored to be saved again
  .map(([key]) => key); // Extract keys into a list

  console.log('removedKeys', {removedKeys, bogoCampaignSet, campaignMetafieldsIndex})

  // Removes the campaign key saved in metafileds
  if (removedKeys.length) {
    for (let key of removedKeys) {
      if (campaignMetafieldsIndex[key]) {
        await DeleteCampaignKey(campaignMetafieldsIndex[key].id, accessToken);
        delete campaignMetafieldsIndex[key];
        delete campaignKeySet[key];
      }
    }
  }

  // Campaign id's are numbers concatenated in a string with "," as a delimiter
  const campaignKeys = Object.keys(campaignKeySet).join(",")

  // Set's the new contacted string of campaign keys available (This will become "storedKeys")
  await SetCampaignKeys(
    { id: campaignKeysID, value: campaignKeys },
    accessToken
  );

  await SetActiveCampaignKey(
    { metafieldID: activeCampaignMetafieldID, value: active },
    accessToken
  );

  // Store the campaign data in metafields
  for (let campaignKey in campaigns) {
    await SetCampaignMetafield(campaigns[campaignKey], accessToken);
  }


  await UpdateShopifyBuyItemsCollection(campaignData, accessToken);
  // for(const campaignKey in bogoCampaignSet){
  //   const bogoCampaign = bogoCampaignSet[campaignKey]
  //   if(!bogoCampaign.active){
  //     // Adjust BOGO inventory
  //     await AdjustBogoVariantInventoryForRemoval(bogoCampaign, accessToken)
  //   }
  // }
  
  await UpdateShopifyGetItemsCollection(campaignData, accessToken, bogoCampaignSet);
  // for(const campaignKey in bogoCampaignSet){
  //   const bogoCampaign = bogoCampaignSet[campaignKey]
  //   if(bogoCampaign.active){
  //     await AdjustCreatedBogoVariantInventory(bogoCampaign, accessToken)
  //   }
  // }

  toast.success("Save Successful");
  // Return with a campaign index and a string of the current keys
  return {
    campaignMetafieldsIndex,
    storedKeys: campaignKeys
  };
}



function findBogoCampaigns(campaignSet){
  return Object.values(campaignSet).reduce((acc, campaign) => {
    const bogoProductIds = findBogoProducts(campaign.customerBuys.map(item => item.id), campaign.customerGets.map(item => item.id))
    if(bogoProductIds.length) acc[campaign.id] = campaign
    return acc
  }, {})
}


/**
 * 
 * @param {Number[]} customerBuyIds 
 * @param {Number[]} customerGetIds 
 */
 function findBogoProducts(customerBuyIds, customerGetIds){
  return customerBuyIds.filter(itemId => customerGetIds.find(giftId => giftId === itemId))
}