// REACT
import { createContext } from "react";

export const AppContext = createContext();

export const shopName = process.env.REACT_APP_SHOPIFY_STORE_NAME;
export const shopNameParam = `shop=${process.env.REACT_APP_SHOPIFY_STORE_NAME}`;
export const campaignBuyItemsCollectionID =
  process.env.REACT_APP_SHOPIFY_BUY_ITEMS_COLLECTION_ID;
export const campaignGetItemsCollectionID =
  process.env.REACT_APP_SHOPIFY_GET_ITEMS_COLLECTION_ID;

export const apiWorker = process.env.NODE_ENV === 'development' 
// Local Cloudflare worker running: `wrangler dev`
? `http://localhost:8787/api`
// Deployed/LIVE
: `/api`

export const headers = (access_token) => ({
  "Content-Type": "application/json",
  "X-Shopify-Access-Token": `${access_token}`,
});
export const getHeaders = (access_token) => ({
  "X-Shopify-Access-Token": `${access_token}`,
});
