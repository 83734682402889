import React, { useContext } from "react";
import { AppContext } from "../../lib";
import SpecialConditionsIndexItem from "./SpecialConditionsIndexItem";
import "./SpecialConditionsIndex.scss";

export default function SpecialConditionsIndex() {
  const { state } = useContext(AppContext);
  const { specialConditions } = state;

  return (
    <main className="specialCondition-index-list">
      {specialConditions ? (
        Object.values(specialConditions.index).map((specialCondition) => (
          <SpecialConditionsIndexItem
            key={`specialCondition-${specialCondition.id}`}
            specialCondition={specialCondition}
          />
        ))
      ) : (
        <span>Loading Special Conditions...</span>
      )}
    </main>
  );
}
