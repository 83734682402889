import CheckForSave from "./CheckForSave"
import SetActiveCampaign from "./SetActiveCampaign"
import TargetCampaignIndex from "./TargetCampaignIndex"

export default function ArchiveCampaign(state, payload){
  const {id, active} = payload
  
  // Deactivate campaign if "active"
  if(active) state = SetActiveCampaign(state, payload)
  
  state.campaigns.index[id].archived = true;

  // Untoggle the view
  state = TargetCampaignIndex(state, {id: null})

  return CheckForSave(state)
}