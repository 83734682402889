import CheckForSave from "./CheckForSave";

export default function UpdateCampaignTierGiftAssignment(state, payload) {
  const { campaigns } = state;
  const { id: campaignID, tierId, product: gift } = payload;
  
  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];
  let { customerGets } = campaign

  if(gift) delete gift.body_html;

  for (const tier of campaign.tiers) {
    if (tier.id === tierId) {
      if(tier.gift && customerGets) customerGets = customerGets.filter(({id}) => id !== tier.gift.id)
      tier.gift = gift;
      customerGets = customerGets 
        ? gift 
          ? [...customerGets, gift] 
          : [...customerGets] 
        : gift 
          ? [gift] 
          : [];
    }
  }

  campaign = { ...campaign, customerGets };
  campaigns.index[campaignID] = {...campaign};
  
  return CheckForSave({ ...state, campaigns });
}
