import GetCampaignBuyItemsCollectIDs from "./GetCampaignBuyItemsCollectIDs";
import DeleteCollectID from "./DeleteCollectID";
import AddCollectToCollection from "./AddCollectToCollection";
import { campaignBuyItemsCollectionID } from "..";

export default async function (campaignData, accessToken) {
  // Pull the product "collects" from a collection called "Campaign Buy Items"
  const { collects } = await GetCampaignBuyItemsCollectIDs(accessToken);
  const { active, index } = campaignData;

  // IF THE FINAL CAMPAIGN IS DEACTIVATED, CLEAR ALL REMAINING "CAMPAIGN BUY ITEM" COLLECTS
  if (!active || active === "0")
    return await wipeCampaignBuyItemCollection(collects, accessToken);

  // Makes a dictionary of all the products required by all active campaigns
  // As well as merging in the existing collection as well
  const collection = mergeExistingCampaignBuyProductsCollection(
    aggregateCampaignBuyProducts(index, active),
    collects
  );

  // Identify products that are already included in the collection
  const includedProducts = [
    ...Object.values(collection).filter((product) => product.included),
  ];

  // Identify products that have not been added to the collection
  const unaddedProducts = [
    ...Object.values(collection).filter((product) => !product.included),
  ];

  // Identify products that no longer need to be in the collection
  const removedProducts = includedProducts.filter((product) => !product.active);

  // DELETE PRODUCTS THAT ARE NOT LONGER QUALIFIED CAMPAIGN BUYS (IF NEEDED)
  if (removedProducts.length) {
    const removeOperations = removedProducts.map(({ collectID }) =>
      DeleteCollectID(collectID, accessToken)
    );
    await Promise.all(removeOperations);
  }

  console.log({
    collection,
    includedProducts,
    unaddedProducts,
    removedProducts,
  });

  // ADD NEW PRODUCTS THAT NOW ADDED AS QUALIFIED CAMPAIGN BUYS (IF NEEDED)
  if (unaddedProducts.length) {
    const createPutOperations = unaddedProducts.map(({ productID }) =>
      AddCollectToCollection(
        productID,
        campaignBuyItemsCollectionID,
        accessToken
      )
    );
    await Promise.all(createPutOperations);
  }
}

function aggregateCampaignBuyProducts(index, activeCampaigns) {
  const collection = {};
  const activeCampaignsKeys = activeCampaigns.split(",");

  activeCampaignsKeys.forEach((campaignKey) => {
    let activatedCampaign = index[campaignKey];
    const { title, status } = activatedCampaign;

    // Skip adding products to collection if the campaign is not live yet
    if (status === "scheduled") {
      console.log(
        `SKIPPING AGGREGATING QAULIFIED BUYS PRODUCTS OF SCHEDULED CAMPAIGN: ${title}`
      );
    } else {
      console.log(`AGGREGATING QAULIFIED BUYS PRODUCTS OF CAMPAIGN: ${title}`);
      activatedCampaign.customerBuys.forEach(({ id }) => {
        collection[id] = {
          productID: id,
          collectID: null,
          included: null,
          active: true,
        };
      });
    }
  });

  return collection;
}

function mergeExistingCampaignBuyProductsCollection(collection, collects) {
  // We need to also factor in the products that already exists withing the collection
  collects.forEach(({ id: collectID, product_id: productID }) => {
    // This will merge all the exists products to the dictionary
    // The flag "included" is added to indicate the product has already been saved in the collection
    if (collection[productID]) {
      collection[productID] = {
        ...collection[productID],
        productID,
        collectID,
        included: true,
      };
    } else {
      collection[productID] = {
        productID,
        collectID,
        included: true,
      };
    }
  });

  return collection;
}

async function wipeCampaignBuyItemCollection(collects, accessToken) {
  const collection = {};
  collects.forEach(({ id, product_id }) => {
    collection[product_id] = collection[product_id]
      ? {
          ...collection[product_id],
          productID: product_id,
          collectID: id,
          included: true,
        }
      : {
          productID: product_id,
          collectID: id,
          included: true,
        };
  });
  const productList = Object.entries(collection).map((product) => product[1]);
  for (let product of productList) {
    await DeleteCollectID(product.collectID, accessToken);
  }
  return;
}
