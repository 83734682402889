import React from "react";

export default function TrashIcon() {
  return (
    <svg
      width="56"
      height="66"
      viewBox="0 0 56 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.6288 7.57783H38.0497V5.1068C38.0497 2.3063 35.7435 0 32.9429 0H22.647C19.8465 0 17.5402 2.3063 17.5402 5.1068V7.57783H2.96108C1.72557 7.57783 0.654785 8.56625 0.654785 9.88413C0.654785 11.202 1.6432 12.1904 2.96108 12.1904H5.59685V56.9161C5.59685 61.6111 9.38577 65.4 14.0807 65.4H41.5092C46.2042 65.4 50.0754 61.5287 50.0754 56.8338V12.0257H52.7936C54.0291 12.0257 55.0999 11.0373 55.0999 9.7194C55.0175 8.56625 53.9467 7.57783 52.6288 7.57783ZM21.988 7.57783V5.1068C21.988 4.69496 22.3175 4.44786 22.647 4.44786H33.0253C33.4372 4.44786 33.6843 4.77733 33.6843 5.1068V7.57783H21.988ZM45.4628 56.7514C45.4628 58.8929 43.7331 60.705 41.5092 60.705H13.9984C11.8568 60.705 10.0447 58.9753 10.0447 56.7514V12.0257H45.4628V56.7514Z"
        fill="currentColor"
      />
      <path
        d="M27.7541 54.198C28.9896 54.198 29.8956 53.2095 29.8956 51.8917V20.839C29.8956 19.6035 28.9072 18.5327 27.7541 18.5327C26.5185 18.5327 25.4478 19.6035 25.4478 20.839V51.8917C25.5301 53.1272 26.5185 54.198 27.7541 54.198Z"
        fill="currentColor"
      />
      <path
        d="M38.1325 54.198C39.368 54.198 40.4388 53.2095 40.4388 51.8917V20.839C40.4388 19.6035 39.4504 18.5327 38.1325 18.5327C36.897 18.5327 35.8262 19.5211 35.8262 20.839V51.974C35.9909 53.2095 36.9793 54.198 38.1325 54.198Z"
        fill="currentColor"
      />
      <path
        d="M17.4577 54.198C18.6932 54.198 19.764 53.2095 19.764 51.8917V20.839C19.764 19.6035 18.7756 18.5327 17.4577 18.5327C16.2222 18.5327 15.1514 19.5211 15.1514 20.839V51.8917C15.1514 53.1272 16.1398 54.198 17.4577 54.198Z"
        fill="currentColor"
      />
    </svg>
  );
}
