import React from "react";

export default function CycleIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16V8.99999H7L3.783 12.22C4.89296 13.355 6.41246 13.9964 8 14C10.5394 13.9962 12.8015 12.3942 13.648 9.99999H13.666C13.7805 9.67461 13.8672 9.34007 13.925 8.99999H15.937C15.4331 12.9999 12.0315 15.9999 8 16H7.99C5.86876 16.0063 3.83316 15.1637 2.337 13.66L0 16ZM2.074 6.99999H0.062C0.56575 3.0016 3.965 0.00213333 7.995 -5.08528e-05H8C10.1216 -0.00683934 12.1577 0.83583 13.654 2.33999L16 -5.08528e-05V6.99999H9L12.222 3.77999C11.1109 2.64364 9.5893 2.00213 8 1.99999C5.46055 2.00374 3.19848 3.60577 2.352 5.99999H2.334C2.21856 6.3251 2.13189 6.65972 2.075 6.99999H2.074Z"
        fill="currentColor"
      />
    </svg>
  );
}
