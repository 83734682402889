import React, { useContext } from "react";
import { AppContext } from "../../../lib";
import "../modal.scss";
import "./AppAlert.scss";

export default function AppAlert() {
  const { state, dispatch } = useContext(AppContext);
  const { activeAlert } = state;
  const { message } = activeAlert;
  const close = () => dispatch({ type: "setActiveAlert", payload: null });

  return (
    <div className="modal appAlert">
      <div className="modal-background" onClick={close} />
      <div className="modal-container">
        <p className="modal-text">{message}</p>
        <div className="modal-actions">
          <button className="modal-action confirm" onClick={close}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
