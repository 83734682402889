import React, { useEffect, useState } from "react";
import { Reset } from "../../../assets/svgs";
import "./DiscountCampaignDate.scss";
import Datetime from 'react-datetime';

export default function DiscountCampaignDate({
  campaign,
  onStartDateChange,
  onEndDateChange,
}) {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const { id } = campaign;

  // const toggleDate = () => {};

  const handleDateChange = (evt, which) => {
    const { _d:value } = evt
    const GTMTime = value ? new Date(value).toGMTString() : null
    console.log('handleDateChange', {evt, which, value, GTMTime})
    
    switch(which){
      case 'startTime':
        return setStartTime({value, GTMTime});
      case 'endTime':
        return setEndTime({value, GTMTime});
      default:
    } 
  };


  const handleDateInputClose = (evt, which) => {
    console.log('handleStartDateClose', {evt, which})
    
    switch(which){
      case 'startTime':
        return onStartDateChange({startTime});
      case 'endTime':
        return onEndDateChange({endTime});
      default:
    } 
  };

  const handleDateInputClear = (which) => {
    switch(which){
      case 'startTime':
        setStartTime(null);
        return onStartDateChange({startTime: null});
      case 'endTime':
        setEndTime(null);
        return onEndDateChange({endTime: null});
      default:
    } 
  };

  useEffect(() => {
    console.log('Discount Campaign Date', campaign)
    setStartTime(campaign.startTime);
    setEndTime(campaign.endTime);
  }, [campaign]);

  return (
    <div className={`campaign-date`}>
      <div className="campaign-date-wrapper">
        <div className="campaign-date-heading">
          <label
            className="campaign-date-start-label"
            htmlFor={`${id}-start-time`}
          >
            Start Time
          </label>
          <DateTimeReset onReset={() => handleDateInputClear('startTime')} />
        </div>
        <Datetime 
          id={`${id}-start-time`}
          name={`${id}-start-time`}
          value={startTime ? new Date(startTime.value) : ''}
          onChange={(evt) => handleDateChange(evt, 'startTime')}
          onClose={(evt) => handleDateInputClose(evt, 'startTime')}
          />
      </div>
      <div className="campaign-date-wrapper">
        <div className="campaign-date-heading">
          <label className="campaign-date-end-label" htmlFor={`${id}-end-time`}>
            End Time
          </label>
          <DateTimeReset onReset={() => handleDateInputClear('endTime')} />
        </div>
        <Datetime 
          id={`${id}-end-time`}
          name={`${id}-end-time`}
          value={endTime ? new Date(endTime.value) : ''}
          onChange={(evt) => handleDateChange(evt, 'endTime')}
          onClose={(evt) => handleDateInputClose(evt, 'endTime')}
          />
      </div>
    </div>
  );
}

const DateTimeReset = ({ onReset: handleReset }) => {
  return (
    <span className="campaign-date-reset" label="Reset" onClick={handleReset}>
      <Reset />
    </span>
  );
};
