export default function (state, payload) {
  let { products } = payload;
  products = { list: [...products], index: {} }
  products.list.forEach((product) => {
    products.index = { ...products.index, [product.id]: product };
  });

  console.log({ ...state, products })
  return { ...state, products };
}
