import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function RemoveSpecialCondition(state, payload) {
  const { campaigns } = state;
  const { specialCondition, campaignID } = payload;
  let campaign = campaigns.index[campaignID];
  let { specialBuyConditions } = campaign;

  // Field may not be defined on older campaign objects
  specialBuyConditions = specialBuyConditions ? specialBuyConditions : [];

  const targetSpecialCondition = specialBuyConditions.find(
    (item) => item.id === specialCondition.id
  );

  const isTargetCondition = (item) => item === targetSpecialCondition;
  const targetIndex = specialBuyConditions.findIndex(isTargetCondition);

  if (!targetSpecialCondition) {
    const message = "This product was not found as a [QUALIFYING CONDITION]"
    return SetActiveAlert(state, {message})
  }

  specialBuyConditions.splice(targetIndex, 1);

  campaign = { ...campaign, specialBuyConditions };

  state.campaigns = campaigns;

  return CheckForSave({ ...state });
}
