import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {
  const { id, active } = payload;
  let { campaigns } = state;

  const {isValid, state: returnState} = !active ? handleCampaignValidation(id, campaigns, state) : {isValid: true, state};
  if (!isValid) return returnState
  
  state = returnState || state

  campaigns = !active
    ? handleCampaignActivation(id, campaigns)
    : handleCampaignDeactivation(id, campaigns);

  return CheckForSave({ ...state, campaigns });
}

function handleCampaignValidation(id, campaigns, state) {
  const campaign = campaigns.index[id];

  switch (campaign.type) {
    case "scheduled":
      return validateScheduledCampaign(campaign, state);
    default:
      return {isValid: true, state};
  }

  function validateScheduledCampaign(campaign, state) {
    const now = Date.now();
    const { startTime, endTime } = campaign;
    const live = !startTime || now >= new Date(startTime.value).getTime();
    const expired = !endTime || now >= new Date(endTime.value).getTime();
    const validation = {isValid: false, state}

    if (!endTime) {
      const message = `This scheduled campaign does not have an end date. \nIf that's intentional please, clear the start time`;
      validation.state = SetActiveAlert(state, {message})
      return validation;
    }

    if (expired) {
      const message = `This scheduled campaign has an end date that has already expired\nExtend the end time date to be valid to activate`;
      validation.state = SetActiveAlert(state, {message})
      return validation
    }
    
    if (live) {
      const message = `This scheduled campaign will automatically be published live when saved.`;
      validation.state = SetActiveAlert(state, {message})
    }

    validation.isValid = true
    return validation;
  }
}

function handleCampaignActivation(id, campaigns) {
  let activeCampaigns = campaigns.active;
  let campaign = campaigns.index[id];

  // Signal that this campaign should be actively looked at.
  campaign.active = true;

  // Handle additional logic if "type: 'scheduled'"
  if (campaign.type === "scheduled") {
    const now = Date.now();
    const { startTime, endTime } = campaign;
    const live = !startTime || now >= new Date(startTime.value).getTime();
    const expired = now >= new Date(endTime.value).getTime();
    if (live) campaign.status = "live";
    else if (expired) campaign.status = "expired";
    else campaign.status = "scheduled";
  } else {
    campaign.status = "live";
  }

  campaigns.index[id] = campaign;

  
  if (activeCampaigns !== "0") {
    let newCampaigns = activeCampaigns.concat(`,`, id);
    campaigns.active = newCampaigns;
  } else {
    campaigns.active = id;
  }

  return { ...campaigns };
}

function handleCampaignDeactivation(id, campaigns) {
  const {active: activeCampaignKeys} = campaigns;
  const [firstActiveCampaignId] = activeCampaignKeys.split(",");
  const isFirstIndex = firstActiveCampaignId === id;
  campaigns.index[id]["active"] = false;
  campaigns.index[id]["status"] = "inactive";

  // check for the delimiter "," to detect if there's more than one active campaign
  if (activeCampaignKeys.includes(",")) {
    // check if campaign being removed is first element in active string
    campaigns.active = isFirstIndex
      ? activeCampaignKeys.replace(`${id},`, ``)
      : activeCampaignKeys.replace(`,${id}`, ``);
  } else {
    campaigns.active = "0";
  }

  return { ...campaigns };
}
