import CheckForSave from "./CheckForSave";

export default function (state, payload) {
  const {value, campaignID} = payload;
  const { campaigns } = state;
  let campaign = campaigns.index[campaignID]
  campaign.info = value
  state.campaigns.index[campaignID] = campaign
  return CheckForSave({...state})
}
