import { campaignBuyItemsCollectionID, campaignGetItemsCollectionID } from "../..";

export default function HydrateCollections(state, payload) {
  let { collections } = payload;
  const {custom_collections, smart_collections} = collections

  collections.index = {}
  custom_collections.forEach(collection => {
    collections.index[collection.id] = collection
  })
  smart_collections.forEach(collection => {
    collections.index[collection.id] = collection
  })
  
  delete collections.index[campaignBuyItemsCollectionID]
  delete collections.index[campaignGetItemsCollectionID]

  return { ...state, collections };
}
