import React from 'react'
import { Link } from 'react-router-dom';
import ScentBeautyLogo from "../../assets/ScentBeautyLogo.png";

export default function HeaderNavigation() {
  return (
    <Link id="header" className="app-header" to="/">
    <img
      className="brand-header-logo"
      src={ScentBeautyLogo}
      alt="Scent Beauty Logo"
    />
    <p>Free Gift App</p>
  </Link>
  )
}
