import React, { useContext } from "react";
import { AppContext } from "../../lib";
import DiscountCampaign from "../DiscountCampaign";
import IndexViewport from "../IndexViewport";
import "./CampaignEditor.scss";
export default function CampaignEditor() {
  const { state, dispatch } = useContext(AppContext);
  const { targetedCampaign } = state;
  
  const handleCampaignClose = (evt) => {
    dispatch({ type: "targetCampaignIndex", payload: { id: null } });
  };
  
  return (
    <div className="campaignEditor">
    <div className="popup-background" onClick={handleCampaignClose}></div>
      <div className="campaignEditor-container">
        <div className="campaignEditor-campaign">
        <h2 className="campaigns-index-header">CAMPAIGNS</h2>
          <DiscountCampaign campaignID={targetedCampaign} />
          <div>
            <p className="index-viewer-text"><u>Notes:</u></p>
            <ul>
              <li className="index-viewer-text">- To toggle a Campaign's active status, press the status tab in the upper right corner</li>
              <li className="index-viewer-text">- Campaigns without a <u><b>Qualifying Buy</b></u>, <u><b>Qualifying Buy</b></u>, or <u><b>Qualifying Condition</b></u> will have no functionality on the store</li>
              <li className="index-viewer-text">- Concurrent running <u><b>Price Tier</b></u> and <u><b>Standard Campaign</b></u> campaigns are not compatible offering identical free gifts</li>
              <li className="index-viewer-text">- Concurrent running <u><b>Price Tier</b></u> campaigns are not compatible offering identical free gifts.</li>
            </ul>
          </div>
          <div>
            <p className="index-viewer-text"><u>Statuses:</u></p>
            <ul>
              <li className="index-viewer-text"><b>"Live"</b> - Currently active on the store</li>
              <li className="index-viewer-text"><b>"Inactive"</b> - Currently saved yet not activated to be visibile to the store</li>
              <li className="index-viewer-text"><b>"Scheduled"</b> - Saved and scheduled to be activated by a scheduled cron service</li>
              <li className="index-viewer-text"><b>"Expired"</b> - A scheduled that has been deactivated by a scheduled cron service</li>
            </ul>
          </div>
        </div>
        <div className="campaignEditor-index">
          <IndexViewport />
        </div>
      </div>
    </div>
  );
}
