import React, { useContext, useState } from "react";
import { AppContext } from "../../lib";
import Loader from "../layout/Loader";
import ProductIndexItem from "./ProductIndexItem";
import "./ProductIndex.scss";

export default function ProductIndex() {
  const { state } = useContext(AppContext);
  const { products } = state;
  const [search, setSearch] = useState("");
  const filteredList = products
    ? Object.values(products.index).filter(
        (product) =>
          product.title &&
          product.title.toLowerCase().includes(search.toLowerCase())
      )
    : [];
  const handleSearchInputChange = ({ target: { value } }) => setSearch(value);

  return (
    <div>
      <input
        className="product-index-search"
        type="text"
        placeholder="Search"
        value={search}
        onChange={handleSearchInputChange}
      />
      <main className="product-index-list">
        {products ? (
          filteredList.map((product) => (
            <ProductIndexItem key={`product-index-${product.id}`} product={product} />
          ))
        ) : (
          <Loader />
        )}
      </main>
    </div>
  );
}
