import React, { useContext } from "react";
import { CheckIcon } from "../../assets/svgs";
import { AppContext } from "../../lib";
import "./SpecialConditionsIndexItem.scss";

export default function SpecialConditionsIndexItem({ specialCondition }) {
  const { state, dispatch } = useContext(AppContext);
  const { selectedSpecialConditions, targetedCampaign } = state;
  const selected = selectedSpecialConditions[specialCondition.id];
  const selectedClass = selected ? "selected" : "";
  const handleSpecialConditionClick = () => {
    !selected
      ? dispatch({
          type: "selectSpecialCondition",
          payload: { specialCondition },
        })
      : dispatch({
          type: "unselectSpecialCondition",
          payload: { specialCondition },
        });
  };

  return specialCondition.id !== 'tieredGifts' ? (
    <div className="specialCondition-index-item" id={specialCondition.id}>
      <div>
        <p className="specialCondition-index-item-id">
          Condition ID: {specialCondition.id}
        </p>
        <p className="specialCondition-index-item-title">
          <u>Condition:</u> {specialCondition.title}
        </p>
      </div>
      <div
        className="specialCondition-index-item-selectBox"
        onClick={handleSpecialConditionClick}
      >
        {targetedCampaign ? (
          <span
            className={`specialCondition-index-item-selectBox-circle ${selectedClass}`}
          >
            {selected ? <CheckIcon /> : null}
          </span>
        ) : null}
      </div>
    </div>
  ) : null
}
