import uuid from "../../utils/uuid";

export default function makeCampaign(){
    return {
        id: uuid(),
        title: "",
        info: "",
        customerBuys: [],
        customerBuysFromCollection: [],
        specialBuyConditions: [],
        customerGets: [],
        active: false,
        startTime: null,
        endTime: null,
        type: "default",
        rewardType: "Standard Gift",
        status: "inactive",
        tiers: [],
        scopedPriceTiers: false
      };
}