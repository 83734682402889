import { headers, apiWorker, shopNameParam } from "..";
import { toast } from "react-toastify";
import { AdjustBogoVariantInventoryForRemoval } from ".";

export default async function(product_id, accessToken, isBogo){
  const {access_token} = accessToken

  // GET: Product Variants
  const {variants} = await getProductVariants(product_id, access_token)
  console.log('DELETING FREE GIFT VARIANT ID: VARIANTS', variants)
  
  // Check for Free Gift Product Variant
  const hasFreeGiftVariant = checkForFreeGiftVariant(variants)
  if(!hasFreeGiftVariant) {
    console.log('THIS PRODUCT HAS NO FREE GIFT VARIANT')
    return
  } else {
    const {id:variant_id} = hasFreeGiftVariant
    if(isBogo){
      console.log('MERGING FREE GIFT INVENTORY', {product_id, variant_id})
      await AdjustBogoVariantInventoryForRemoval(product_id, accessToken)
    }
    console.log('DELETING FREE GIFT VARIANT ID', variant_id)
    return await deleteFreeGiftVariant(product_id, variant_id, access_token)
  }
}


async function getProductVariants(product_id, access_token){
  return await fetch(`${apiWorker}/productVariants?${shopNameParam}&product_id=${product_id}`,{
    method: 'GET',
    headers: headers(access_token)
  })
  .then(res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=>{
          window.location.reload()
        }, 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = res.json()
    return json
  })
  .catch(err => {
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}


function checkForFreeGiftVariant(variants){
  return variants.find(({title}) => title === "FREEGIFT")
}

async function deleteFreeGiftVariant(product_id, variant_id, access_token){
  console.log("DELETING", {product_id, variant_id})
  return await fetch(`${apiWorker}/productVariant?${shopNameParam}&product_id=${product_id}&variant_id=${variant_id}`,{
    method: 'DELETE',
    headers: headers(access_token)
  })
  .then(async res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=> window.location.reload(), 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = await res.json()
    console.log("DELETING FREE GIFT", json)
    return json
  })
  .catch(err => {
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}