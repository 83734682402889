import {headers, apiWorker, shopNameParam } from "..";
import { toast } from "react-toastify";

export default async function (metafieldID, accessToken){
  const {access_token} = accessToken
  return await fetch(
  `${apiWorker}/shopMetafield?${shopNameParam}&metafield_id=${metafieldID}`,
  {
    method: "DELETE",
    headers: headers(access_token)
  }).then(res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=>{
          window.location.reload()
        }, 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
  })
  .catch(error => {
    toast.error("An error occured while saving, please refresh the app and save again")
  })
}