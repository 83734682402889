import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../lib";
import { GetCampaignData } from "../../lib/shopifyAPI";
import "./CampaignIndex.scss";
import DiscountCampaign from "../DiscountCampaign";
import Loader from "../layout/Loader";
import { DownCaret } from "../../assets/svgs";

export default function CampaignIndex() {
  const [archiveListOpen, setArchiveListOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const { campaigns, accessToken } = state;
  const archiveListOpenClass = archiveListOpen ? "open" : "";
  const unarchivedCampaigns = campaigns
    ? Object.values(campaigns.index).filter((campaign) => !campaign.archived)
    : [];
  const archivedCampaigns = campaigns
    ? Object.values(campaigns.index).filter((campaign) => campaign.archived)
    : [];

  useEffect(() => {
    (async () => {
      const campaigns = await GetCampaignData(accessToken);
      if (!campaigns) return;
      console.log("campaigns", campaigns);
      dispatch({ type: "hydrateCampaigns", payload: { campaigns } });
    })();
  }, [dispatch, accessToken]);

  const createNewStandardCampaign = () =>
    dispatch({
      type: "createNewCampaign",
      payload: { rewardType: "Standard Gift" },
    });

  const createNewTierCampaign = () =>
    dispatch({
      type: "createNewCampaign",
      payload: { rewardType: "Tiered Gifts" },
    });

  const toggleArchiveList = () => setArchiveListOpen((val) => !val);

  return (
    <div className="campaigns-index">
      <h2 className="campaigns-index-header">CAMPAIGNS</h2>
      <div className="campaigns-index-new">
        <div className="campaigns-index-new-options" tabIndex="0">
          <button
            className="campaigns-index-new-option"
            onClick={createNewStandardCampaign}
            tabIndex="0"
          >
            STANDARD CAMPAIGN
          </button>
          <button
            tabIndex="0"
            className="campaigns-index-new-option tiered"
            onClick={createNewTierCampaign}
          >
            PRICE TIER CAMPAIGN
          </button>
        </div>

        <div className="campaigns-index-new-cover" tabIndex="0">
          CREATE NEW CAMPAIGN +
        </div>
      </div>
      <div className="campaigns-index-fadeEdge"></div>
      <div className="campaigns-index-list">
        {campaigns ? (
          campaigns.index ? (
            unarchivedCampaigns.map((campaign) => (
              <DiscountCampaign
                key={`campaign-${campaign.id}`}
                campaignID={campaign.id}
              />
            ))
          ) : null
        ) : (
          <Loader />
        )}
      </div>

      {campaigns ? (
        campaigns.index ? (
          <div
            className={`campaigns-archive ${archiveListOpenClass}`}
            onClick={toggleArchiveList}
          >
            <div className="campaigns-archive-topbar">
              <span className="campaigns-archive-topbar-label">
                Archived
                <span className="campaigns-archive-topbar-icon">
                  <DownCaret />
                </span>
              </span>
            </div>
            <div className="campaigns-archive-list">
              {archivedCampaigns.map((campaign) => (
                <DiscountCampaign
                  key={`campaign-${campaign.id}`}
                  campaignID={campaign.id}
                />
              ))}
            </div>
          </div>
        ) : null
      ) : null}
    </div>
  );
}
