import React, { useContext } from 'react'
import MainApp from '../components/page/MainApp'
import { AppContext } from '../lib'
import { Redirect } from 'react-router-dom'

export default function MainRoute() {
  const {state} = useContext(AppContext)
  const {accessToken} = state
  return !!accessToken ? <MainApp /> : <Redirect to="/login" />
}

