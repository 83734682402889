import { campaignGetItemsCollectionID, getHeaders, apiWorker, shopNameParam } from "..";
import { toast } from "react-toastify";

export default async function (accessToken) {
  const { access_token } = accessToken;

  return fetch(`${apiWorker}/collectionCollects?${shopNameParam}&collection_id=${campaignGetItemsCollectionID}`, {
    method: "GET",
    headers: getHeaders(access_token),
  })
    .then((res) => {
      if(!res.ok){
        if(res.status === 401){
          localStorage.removeItem('accessT')
          toast.warn("Your session has expired, please sign in again")
          setTimeout(()=>{
            window.location.reload()
          }, 3500)
        } else {
          toast.error("An error occured while saving, please refresh the app and save again")
        }
      }
      const json = res.json();
      return json;
    })
    .catch((err) => {
      toast.error(
        "An error occured while saving, please refresh the app and save again"
      );
    });
}
