import { makeCampaignTier } from "../helpers";
import CheckForSave from "./CheckForSave";

export default function AddTierToCampaign(state, payload) {
  const { campaigns } = state;
  const { id: campaignID } = payload;
  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];

  // Create the initial tier value in tiers array
  /** @type {GWPCampaignTieredGiftsTier} */
  const tier = makeCampaignTier();
  campaign.tiers = campaign.tiers ? [...campaign.tiers, tier] : [tier];

  campaigns.index[campaignID] = campaign;
  return CheckForSave({ ...state, campaigns })
}
