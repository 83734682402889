import { getHeaders, apiWorker, shopName } from "..";
import { toast } from "react-toastify";

export default async function GetAllCollections(accessToken) {
  const {access_token} = accessToken
  const res = await fetch(`${apiWorker}/allCollections?shop=${shopName}`, {
    method: 'GET',
    headers:getHeaders(access_token)
  })


  if(!res.ok){
    if(res.status === 401){
      localStorage.removeItem('accessT')
      toast.warn("Your session has expired, please sign in again")
      setTimeout(()=>{
        window.location.reload()
      }, 3500)
    }
  }

  const { collections } = await res.json()
  console.log('GetAllCollections', collections)
  return collections;
}