import CheckForSave from "./CheckForSave";

export default function UpdateCampaignTierMaxSpend(state, payload) {
  const { campaigns } = state;
  const { id: campaignID, tierId, price } = payload;

  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];

  for(const tier of campaign.tiers){
    if(tier.id === tierId) tier.maxSpend = price
  }

  campaigns.index[campaignID] = campaign;
  return CheckForSave({ ...state, campaigns })
}
