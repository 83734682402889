import GetShopMetafields from "./GetShopMetafields";

export default async function (accessToken) {
  const keysRes = await GetShopMetafields(accessToken);
  const campaignNamespaceKey = "campaigns";
  const campaignKeyField = "campaignKeys";
  const activeCampaignField = "activeCampaign";

  /** @type {Array} */
  const campaignNamespace = keysRes.metafields.filter(
    ({ namespace }) => namespace === campaignNamespaceKey
  );
  const campaignKeys = campaignNamespace.filter(
    ({ key }) => key === campaignKeyField
  );
  const activeCampaign = campaignNamespace.filter(
    ({ key }) => key === activeCampaignField
  );
  let campaignMetafields = campaignNamespace.filter(
    ({ key }) => key !== campaignKeyField && key !== activeCampaignField
  );
  const campaigns = campaignMetafields.map(({ value }) => JSON.parse(value));
  const campaignMetafieldsIndex = {};
  campaignMetafields.forEach((field) => {
    campaignMetafieldsIndex[field.key] = field;
  });

  if (!campaigns.length) return;

  let index = {};
  let originKeys = campaignKeys[0].value;
  let active = activeCampaign[0].value;

  campaigns.forEach((campaign) => (index[campaign.id] = campaign));

  const campaignKeysID = campaignKeys[0].id;
  const activeCampaignMetafieldID = activeCampaign[0].id;

  const data = {
    index,
    active,
    originKeys,
    campaignMetafieldsIndex,
    activeCampaignMetafieldID,
    campaignKeysID,
  };
  return data;
}
