import uuid from "../../utils/uuid";
import CheckForSave from "./CheckForSave";
import TargetCampaignIndex from "./TargetCampaignIndex";

export default function DuplicateCampaign(state, payload){
  const {id:campaignID} = payload;
  const { campaigns } = state;
  let newCampaign = {...campaigns.index[campaignID]}
  
  const id = uuid()
  newCampaign.id = id
  newCampaign.title = `Copy of ${newCampaign.title}`
 
  campaigns.index[id] = newCampaign;

  state.campaigns = campaigns;

  state = TargetCampaignIndex(state, { id });

  setTimeout(() => {
    const element = document.getElementById(id);
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - window.innerHeight / 2;
    window.scrollTo({
      top: middle,
      left: 0,
      behavior: "smooth",
    });
  }, 500);

  return CheckForSave(state);
}