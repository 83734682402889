import * as AppActions from "./lib";

export default function AppReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "readyApp": {
      return { ...state, ready: true };
    }
    case "suspendApp": {
      return { ...state, ready: false };
    }
    case "assignAccessToken": {
      return { ...state, accessToken: payload };
    }
    case "saveCampaigns": {
      return AppActions.SaveCampaigns(state, payload);
    }
    case "hydrateState": {
      return AppActions.HydrateState(state, payload);
    }
    case "hydrateCampaigns": {
      return AppActions.HydrateCampaignIndex(state, payload);
    }
    case "hydrateProducts": {
      return AppActions.HydrateProducts(state, payload);
    }
    case "targetProductIndex": {
      return AppActions.TargetProductIndex(state, payload);
    }
    case "clearTargetProduct": {
      return { ...state, targetedProduct: false };
    }
    case "createNewCampaign": {
      return AppActions.CreateNewCampaign(state, payload);
    }
    case "targetCampaignIndex": {
      return AppActions.TargetCampaignIndex(state, payload);
    }
    case "removeCampaign": {
      return AppActions.RemoveCampaign(state, payload);
    }
    case "assignBuyProduct": {
      return AppActions.AssignBuyProduct(state, payload);
    }
    case "removeBuyProduct": {
      return AppActions.RemoveBuyProduct(state, payload);
    }
    case "assignGetProduct": {
      return AppActions.AssignGetProduct(state, payload);
    }
    case "removeGetProduct": {
      return AppActions.RemoveGetProduct(state, payload);
    }
    case "updateCampaignTitle": {
      return AppActions.UpdateCampaignTitle(state, payload);
    }
    case "updateCampaignInfo": {
      return AppActions.UpdateCampaignInfo(state, payload);
    }
    case "setActiveCampaign": {
      return AppActions.SetActiveCampaign(state, payload);
    }
    case "setStartTime": {
      return AppActions.SetCampaignStartTime(state, payload);
    }
    case "setEndTime": {
      return AppActions.SetCampaignEndTime(state, payload);
    }
    case "hydrateCollections": {
      return AppActions.HydrateCollections(state, payload);
    }
    case "selectView": {
      return AppActions.SelectView(state, payload);
    }
    case "selectProduct": {
      return AppActions.SelectProduct(state, payload);
    }
    case "unselectProduct": {
      return AppActions.UnselectProduct(state, payload);
    }
    case "addSelectedAsFreeGift": {
      return AppActions.AddSelectedAsFreeGift(state, payload);
    }
    case "addSelectedAsQualifyingBuy": {
      return AppActions.AddSelectedAsQualifyingBuy(state, payload);
    }
    case "selectCollection": {
      return AppActions.SelectCollection(state, payload);
    }
    case "unselectCollection": {
      return AppActions.UnselectCollection(state, payload);
    }
    case "addSelectedCollectionsAsQualifyingBuy": {
      return AppActions.AddSelectedCollectionsAsQualifyingBuy(state, payload);
    }
    case "removeBuyFromCollection": {
      return AppActions.RemoveBuyFromCollection(state, payload);
    }
    case "selectSpecialCondition": {
      return AppActions.SelectSpecialCondition(state, payload);
    }
    case "unselectSpecialCondition": {
      return AppActions.UnselectSpecialCondition(state, payload);
    }
    case "addSelectedSpecialConditionAsQualifyingBuy": {
      return AppActions.AddSelectedSpecialConditionsAsQualifyingBuy(
        state,
        payload
      );
    }
    case "removeSpecialCondition": {
      return AppActions.RemoveSpecialCondition(state, payload);
    }
    case "convertCampaign": {
      return AppActions.ConvertCampaign(state, payload);
    }
    case "addTier": {
      return AppActions.AddTierToCampaign(state, payload);
    }
    case "removeTier": {
      return AppActions.RemoveTierFromCampaign(state, payload);
    }
    case "resetTier": {
      return AppActions.ResetTierOfCampaign(state, payload);
    }
    case "updateTierMinSpend": {
      return AppActions.UpdateCampaignTierMinSpend(state, payload);
    }
    case "updateTierMaxSpend": {
      return AppActions.UpdateCampaignTierMaxSpend(state, payload);
    }
    case "updateTierGiftAssignment": {
      return AppActions.UpdateCampaignTierGiftAssignment(state, payload);
    }
    case "toggleScopedPriceTiers": {
      return AppActions.ToggleScopedPriceTiers(state, payload);
    }
    case "needsUserConfirmation": {
      return AppActions.SetNeedsConfirmation(state, payload);
    }
    case "setActiveAlert": {
      return AppActions.SetActiveAlert(state, payload);
    }
    case "archiveCampaign": {
      return AppActions.ArchiveCampaign(state, payload);
    }
    case "unarchiveCampaign": {
      return AppActions.UnarchiveCampaign(state, payload);
    }
    case "duplicateCampaign": {
      return AppActions.DuplicateCampaign(state, payload);
    }
    default:
      return state;
  }
}
