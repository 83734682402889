import React, { useContext, useState } from "react";
import { AppContext } from "../../lib";
import "./ViewSelector.scss";

export default function ViewSelector() {
  const { state, dispatch } = useContext(AppContext);
  const { currentView } = state;
  const [views] = useState({
    products: {
      key: "products",
      label: "Products",
    },
    collections: {
      key: "collections",
      label: "Collections",
    },
    "special conditions": {
      key: "special conditions",
      label: "Conditions",
    },
  });

  const selectView = (key) =>
    dispatch({ type: "selectView", payload: { key } });

  return (
    <div className="viewSelector">
      <p className="viewSelector-label">View: <span className="index-viewer-text">Press buttons to switch</span></p>
      <div className="viewSelector-selections">
        {Object.values(views).map((view, i) => (
          <ViewSelectorButton
            key={`view-selection-button-${i}`}
            currentView={currentView}
            view={view}
            onSelect={selectView}
          />
        ))}
      </div>
    </div>
  );
}

function ViewSelectorButton({ currentView, view, onSelect: handleSelect }) {
  const { label, key } = view;
  const isSelectedClass = currentView === key ? "selected" : "";
  return (
    <button
      className={`viewSelector-selection ${isSelectedClass}`}
      key={`viewSelector-selection-${key}`}
      onClick={() => handleSelect(key)}
    >
      {label}
    </button>
  );
}
