import { headers, shopNameParam, apiWorker } from "..";
import { toast } from "react-toastify";

export default async function (campaign, accessToken) {
  const { access_token } = accessToken;
  const keyString = campaign.id;
  const valueString = JSON.stringify(campaign);

  return await fetch(
    `${apiWorker}/shopMetafields?${shopNameParam}`,
    {
      method: "POST",
      headers: headers(access_token),
      body: JSON.stringify({
        metafield: {
          namespace: "campaigns",
          key: keyString,
          value: valueString,
          value_type: "string",
        },
      }),
    }
  )
    .then((res) => {
      if(!res.ok){
        if(res.status === 401){
          localStorage.removeItem('accessT')
          toast.warn("Your session has expired, please sign in again")
          setTimeout(()=>{
            window.location.reload()
          }, 3500)
        } else {
          toast.error("An error occured while saving, please refresh the app and save again")
        }
      }
      const json = res.json();
      return json;
    })
    .catch((err) => {
      toast.error(
        "An error occured while saving, please refresh the app and save again"
      );
    });
}
