import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {
  const { product, campaignID } = payload;
  const { campaigns } = state;
  let campaign = campaigns.index[campaignID];
  console.log('removeBuyProduct', {payload, campaign})
  let { customerBuys } = campaign;

  const targetProduct = customerBuys.find((item) => item.id === product.id);
  if (!targetProduct) {
    const message = "This product was not found as a [QUALIFYING BUY] product"
    return SetActiveAlert(state, {message})
  }

  const isTargetProduct = (item) => item === targetProduct
  const targetIndex = customerBuys.findIndex(isTargetProduct);

  customerBuys.splice(targetIndex, 1);

  campaign = { ...campaign, customerBuys };

  state.campaigns = campaigns;

  return CheckForSave({...state});
}
