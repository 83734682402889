import React, { useContext } from "react";
import { AppContext } from "../../../lib";
import { TrashIcon } from "../../../assets/svgs";
import "./CampaignProductItem.scss";

export default function CampaignProductItem({ product, type, campaign }) {
  const { dispatch } = useContext(AppContext);
  const { id, title } = product;
  const { archived, id: campaignID } = campaign;
  const actionType = type === "buy" ? "removeBuyProduct" : "removeGetProduct";

  const askToUnarchive = () =>
    dispatch({
      type: "setActiveAlert",
      payload: {
        message:
          "This campaign is archived. Please unarchive this campaign to make this edit.",
      },
    });

  const removeProduct = () =>
    archived
      ? askToUnarchive()
      : dispatch({
          type: actionType,
          payload: { campaignID, product },
        });

  return (
    <div className="campaignProductItem" key={`buy-${id}`}>
      <span className="campaignProductItem-label">{title}</span>
      <button
        className="campaignProductItem-remove"
        title={`Remove ${title}`}
        onClick={removeProduct}
      >
        <TrashIcon />
      </button>
    </div>
  );
}
