import { headers, apiWorker, shopNameParam } from "..";
import { toast } from "react-toastify";

export default async function(product_id, collection_id, accessToken){
  const {access_token} = accessToken
  const createCollectPayload = {
    "collect": {
      "product_id": product_id,
      "collection_id": collection_id
    }
  }

  return await fetch(`${apiWorker}/collect?${shopNameParam}`,{
    method: 'POST',
    headers: headers(access_token),
    body: JSON.stringify(createCollectPayload)
  })
  .then(res => {
    if(!res.ok){
      if(res.status === 401){
        localStorage.removeItem('accessT')
        toast.warn("Your session has expired, please sign in again")
        setTimeout(()=>{
          window.location.reload()
        }, 3500)
      } else {
        toast.error("An error occured while saving, please refresh the app and save again")
      }
    }
    const json = res.json()
    return json
  })
  .catch(err => {
    toast.error('An error occured while saving, please refresh the app and save again')
  })
}