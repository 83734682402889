import React from "react";

export default function ArchiveIcon() {
  return (
    <svg
      width="73"
      height="60"
      viewBox="0 0 73 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0719 16.958H67.8324V6.35925C67.8324 5.19126 66.8828 4.2395 65.7127 4.2395H6.35973C5.18963 4.2395 4.23998 5.19126 4.23998 6.35925V16.958H0.000488281V6.35925C0.000488281 2.85318 2.85367 0 6.35973 0H65.7127C69.2188 0 72.0719 2.85318 72.0719 6.35925V16.958Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.4725 59.353H10.5985C7.09244 59.353 4.23926 56.4998 4.23926 52.9937V16.958H8.47875V52.9937C8.47875 54.1617 9.4284 55.1135 10.5985 55.1135H61.4725C62.6426 55.1135 63.5922 54.1617 63.5922 52.9937V16.958H67.8317V52.9937C67.8317 56.4998 64.9785 59.353 61.4725 59.353Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5147 29.6765H27.5568C26.3845 29.6765 25.437 28.7269 25.437 27.5568C25.437 26.3867 26.3845 25.437 27.5568 25.437H44.5147C45.687 25.437 46.6345 26.3867 46.6345 27.5568C46.6345 28.7269 45.687 29.6765 44.5147 29.6765Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.7185H72.0714V16.958H0V12.7185Z"
        fill="currentColor"
      />
    </svg>
  );
}
