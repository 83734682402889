import {
  GetCampaignGetItemsCollectIDs,
  DeleteCollectID,
  AddCollectToCollection,
} from ".";
import { campaignGetItemsCollectionID } from "..";
import DeleteFreeGiftVariant from "./DeleteFreeGiftVariant";
import TouchFreeGiftVariant from "./TouchFreeGiftVariant";

export default async function UpdateShopifyGetItemsCollection(campaignData, accessToken, bogoCampaignSet) {
  // Pull the product "collects" from a collection called "Campaign Buy Items"
  const { collects } = await GetCampaignGetItemsCollectIDs(accessToken);
  const { active, index } = campaignData;

  // IF THE FINAL CAMPAIGN IS DEACTIVATED, CLEAR ALL REMAINING "CAMPAIGN BUY ITEM" COLLECTS
  if (!active || active === "0")
    return await wipeCampaignGetItemCollectionAndVariants(
      collects,
      accessToken
    );

  const bogoProductIds = Object.values(bogoCampaignSet).reduce((acc, bogoCampaign) => {
    const bogoProductIds = findBogoProducts(
      bogoCampaign.customerBuys.map(item => item.id),
      bogoCampaign.customerGets.map(item => item.id)
    )
    return [...acc, ...bogoProductIds]
  }, [])

  // Makes a dictionary of all the products required by all active campaigns
  // As well as merging in the existing collection as well
  const collection = mergeExistingProductsCollection(
    aggregateCampaignGetProducts(index, active),
    collects
  );

  // Identify products that are already included in the collection
  const includedProducts = [
    ...Object.values(collection).filter((product) => product.included),
  ];

  // Identify products that have not been added to the collection
  const unaddedProducts = [
    ...Object.values(collection).filter((product) => !product.included),
  ];
  // const unaddedBogoProducts = unaddedProducts.filter(({productID}) => bogoProductIds.includes(productID))

  // Identify products that no longer need to be in the collection
  const removedProducts = includedProducts.filter((product) => !product.active);
  // const removedBogoProducts = removedProducts.filter(({productID}) => bogoProductIds.includes(productID))

  console.log('bogoProductIds', bogoProductIds)


  // DELETE PRODUCTS THAT ARE NOT LONGER GWP OPTIONS (IF NEEDED)
  if (removedProducts.length) {
    let removeOperations = [];
    removedProducts.forEach(({ productID }) => {
      const isBogo = bogoProductIds.includes(productID)
      console.log('removedProduct:productID', {isBogo})
      removeOperations.push(DeleteFreeGiftVariant(productID, accessToken, isBogo))
    });

    removedProducts.forEach(({ collectID }) =>
      removeOperations.push(DeleteCollectID(collectID, accessToken))
    );

    await Promise.all(removeOperations);
  }

  // ADD NEW PRODUCTS AND THEIR VARIANTS THAT ARE NOW ADDED AS GWP (IF NEEDED)
  if (unaddedProducts.length) {
    let createPutOperations = [];
    
    unaddedProducts.forEach(({ productID }) => {
      const isBogo = bogoProductIds.includes(productID)
      console.log('unaddedProducts:productID', {isBogo})
      createPutOperations.push(TouchFreeGiftVariant(productID, accessToken, isBogo));
    });

    unaddedProducts.forEach(({ productID }) => {
      createPutOperations.push(
        AddCollectToCollection(
          productID,
          campaignGetItemsCollectionID,
          accessToken
        )
      );
    });

    await Promise.all(createPutOperations);
  }
}

function aggregateCampaignGetProducts(index, activeCampaigns) {
  const collection = {};
  const activeCampaignsKeys = activeCampaigns.split(",");

  activeCampaignsKeys.forEach((campaignKey) => {
    let activatedCampaign = index[campaignKey];
    const { title, status } = activatedCampaign;

    // Skip adding products to collection if the campaign is not live yet
    if (status === "scheduled") {
      console.log(
        `SKIPPING AGGREGATING QAULIFIED GWP PRODUCTS OF SCHEDULED CAMPAIGN: ${title}`
      );
    } else {
      console.log(`AGGREGATING QAULIFIED GWP PRODUCTS OF CAMPAIGN: ${title}`);
      activatedCampaign.customerGets.forEach(({ id }) => {
        collection[id] = {
          productID: id,
          collectID: null,
          included: null,
          active: true,
        };
      });
    }
  });

  return collection;
}

function mergeExistingProductsCollection(collection, collects) {
  // We need to also factor in the products that already exists withing the collection
  collects.forEach(({ id: collectID, product_id: productID }) => {
    // This will merge all the exists products to the dictionary
    // The flag "included" is added to indicate the product has already been saved in the collection
    if (collection[productID]) {
      collection[productID] = {
        ...collection[productID],
        productID,
        collectID,
        included: true,
      };
    } else {
      collection[productID] = {
        productID,
        collectID,
        included: true,
      };
    }
  });

  return collection;
}

async function wipeCampaignGetItemCollectionAndVariants(collects, accessToken) {
  let collection = {};
  collects.forEach(({ id, product_id }) => {
    collection[product_id] = collection[product_id]
      ? {
          ...collection[product_id],
          productID: product_id,
          collectID: id,
          included: true,
        }
      : {
          productID: product_id,
          collectID: id,
          included: true,
        };
  });
  const productList = Object.entries(collection).map((product) => product[1]);
  for (let product of productList) {
    // remove FREEGIFT variant
    await DeleteFreeGiftVariant(product.productID, accessToken);
    // remove product from customer gets colllection
    await DeleteCollectID(product.collectID, accessToken);
  }
  return;
}

/**
 * 
 * @param {Number[]} customerBuyIds 
 * @param {Number[]} customerGetIds 
 */
 function findBogoProducts(customerBuyIds, customerGetIds){
  return customerBuyIds.filter(itemId => customerGetIds.find(giftId => giftId === itemId))
}