import React, { useState } from "react";
import "./LogItem.scss";

export default function LogItem({ log }) {
  const { message, timestamp, data } = log || {};
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`logItem ${isExpanded ? "expanded" : ""}`}>
      <div
        className="logItem-heading"
        onClick={() => setIsExpanded((val) => !val)}
      >
        <span className="logItem-heading-timestamp">
          {new Date(timestamp).toLocaleString("en-US")}
        </span>
        <p className="logItem-heading-message">
          <span className="logItem-message-label">INFO</span>
          <span className="logItem-message-text">{message}</span>
        </p>
      </div>
      <div className="logItem-info">
        {message ? <LogInfoProperty label="message" value={message} /> : null}
        {data ? <LogInfoProperty label="data" value={data} /> : null}
        <div></div>
      </div>
    </div>
  );
}

function LogInfoProperty({ label, value }) {
  return (
    <div className="logItem-info-property">
      <span className="logItem-info-property-label">{label}</span>
      <pre className="logItem-info-property-value">
        {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
      </pre>
    </div>
  );
}
