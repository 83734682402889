import React, { useContext } from 'react'
import { AppContext } from '../../lib'
import SaveButton from '../SaveButton'
import CampaignIndex from '../CampaignIndex'
import CampaignEditor from '../CampaignEditor'
import AppConfirm from '../Modals/AppConfirm'
import AppAlert from '../Modals/AppAlert'
import LogsLink from '../LogsLink'

export default function MainApp() {
  const {state} = useContext(AppContext)
  const {shouldSave, targetedCampaign, needsConfirmation, activeAlert} = state
  
  return (
    <div>
      {shouldSave ? <SaveButton /> : null}
      
      <LogsLink />

      <div className="app-target-viewports">
        <CampaignIndex />
      </div>

      {targetedCampaign ? <CampaignEditor/> : null}

      {needsConfirmation ? <AppConfirm/> : null}
      {activeAlert ? <AppAlert/> : null}

    </div>
  )
}
