import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext, apiWorker, shopName } from "../../lib";
import { toast } from "react-toastify";

export default function Auth() {
  const {dispatch} = useContext(AppContext)
  const location = useLocation();
  const { search } = location;

  useEffect(() => {
    (async ()=>{
      const location = new URL(window.location.href)
      const code = location.searchParams.get('code')

      if(!code){
        toast.error("No code parameter from Shopify was recevied during authorization")
        return
      }

      const token = await fetch(`${apiWorker}/getToken?shop=${shopName}`, {
        method: 'POST',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({code})
      }).then(res => res.json())

      const {access_token, scope} = token
      if(access_token && scope){
        localStorage.setItem('accessT', JSON.stringify(token))
        dispatch({type: 'assignAccessToken', payload: token})
      }
    })();
  }, [search, dispatch]);
  return <div></div>;
}
