/**
 * This is to notify that something has mutated in state
 * "currentCampaigns" is a copy of the last saved campaign stored in local storage
 */
export default function CheckForSave(state){
  const {currentCampaigns, campaigns} = state
  const state1 = currentCampaigns
  const state2 = JSON.stringify(campaigns)
  const shouldSave = (state1 !== state2) ? true : false 
  return {...state, shouldSave}
}