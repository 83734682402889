import React from "react";
import { Reset } from "../../../../assets/svgs";

export default function TieredGiftPopupTopbar({
  tier,
  campaign,
  onGiftReset: handleGiftReset,
}) {
  const { title, id: campaignID } = campaign;
  const { index, minSpend, maxSpend, gift } = tier;
  const resetGift = () => handleGiftReset({ tierId: tier.id, product: null });

  return (
    <div className="popup-top">
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">Campaign ID:</span>
        <span>{campaignID}</span>
      </div>
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">Campaign Name:</span>
        <span>{title}</span>
      </div>
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">Tier:</span>
        <span>{index + 1}</span>
      </div>
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">Min Spend:</span>
        <span>${minSpend}</span>
      </div>
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">Max Spend:</span>
        <span>${maxSpend}</span>
      </div>
      <div className="popup-tierInfo">
        <span className="popup-tierInfo-label">
          <span>Gift:</span>
          <GiftReset gift={gift} onClick={resetGift} />
        </span>
        <span>{gift ? gift.title : ""}</span>
      </div>
    </div>
  );
}

const GiftReset = ({ gift, onClick: handleClick }) =>
  gift ? <span onClick={handleClick}>
    <Reset/>
  </span> : null;
