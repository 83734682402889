import React, { useContext } from "react";
import { CheckIcon } from "../../assets/svgs";
import { AppContext } from "../../lib";
import "./CollectionIndexItem.scss";

export default function CollectionIndexItem({ collection }) {
  const { state, dispatch } = useContext(AppContext);
  const { selectedCollections, targetedCampaign } = state;
  const selected = selectedCollections[collection.id];
  const selectedClass = selected ? "selected" : "";
  const handleCollectionClick = () => {
    !selected
      ? dispatch({ type: "selectCollection", payload: { collection } })
      : dispatch({ type: "unselectCollection", payload: { collection } });
  };

  return (
    <div className="collection-index-item" id={collection.id}>
      <div>
        <p className="collection-index-item-id">Collection ID: {collection.id}</p>
        <p className="collection-index-item-title">
          {collection.title}
        </p>
      </div>
      <div
        className="collection-index-item-selectBox"
        onClick={handleCollectionClick}
      >
        {targetedCampaign ? (
          <span
            className={`collection-index-item-selectBox-circle ${selectedClass}`}
          >
            {selected ? <CheckIcon /> : null}
          </span>
        ) : null}
      </div>
    </div>
  );
}
