import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {
  const { product, campaignID } = payload;
  const { campaigns } = state;
  let campaign = campaigns.index[campaignID];
  let { customerBuys, customerGets } = campaign;

  if(customerBuys.find(item => item.id === product.id)) {
    const message = 'This product is already added as a [QUALIFYING BUY] product'
    return SetActiveAlert(state, {message})
  }

  if(customerGets.find(item => item.id === product.id)) {
    const message = 'This product is already added as a [FREE GIFT] product'
    return SetActiveAlert(state, {message})
  }
  delete product.body_html

  customerBuys = customerBuys ? [...customerBuys, product] : [product];
  campaign = { ...campaign, customerBuys };

  state.campaigns.index[campaignID] = campaign

  return CheckForSave({...state})
}
