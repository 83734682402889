import React from "react";

export default function DuplicateIcon() {
  return (
    <svg
      width="52"
      height="64"
      viewBox="0 0 52 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 64H32C36.41 64 40 60.41 40 56V54H44C48.41 54 52 50.41 52 46V8C52 3.59 48.41 0 44 0H18C13.59 0 10 3.59 10 8V12H8C3.59 12 0 15.59 0 20V56C0 60.41 3.59 64 8 64ZM14 8C14 5.79 15.79 4 18 4H44C46.21 4 48 5.79 48 8V46C48 48.21 46.21 50 44 50H18C15.79 50 14 48.21 14 46V8ZM4 20C4 17.79 5.79 16 8 16H10V46C10 50.41 13.59 54 18 54H36V56C36 58.21 34.21 60 32 60H8C5.79 60 4 58.21 4 56V20ZM20 28C20 26.89 20.89 26 22 26H40C41.11 26 42 26.89 42 28C42 29.11 41.11 30 40 30H22C20.89 30 20 29.11 20 28ZM20 18C20 16.89 20.89 16 22 16H40C41.11 16 42 16.89 42 18C42 19.11 41.11 20 40 20H22C20.89 20 20 19.11 20 18ZM20 38C20 36.89 20.89 36 22 36H40C41.11 36 42 36.89 42 38C42 39.11 41.11 40 40 40H22C20.89 40 20 39.11 20 38Z"
        fill="currentColor"
      />
    </svg>
  );
}
