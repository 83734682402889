import React, { useContext, useState } from "react";
import { PlusIcon } from "../../../assets/svgs";
import { AppContext } from "../../../lib";
import PriceTierField from "./PriceTierField";
import ProductSelectionPopup from "./ProductSelectionPopup";
import "./TieredGiftConfigurationView.scss";

/**
 *
 * @param {{GWPCampaign}}
 * @returns
 */
export default function TieredGiftConfigurationView({ campaign }) {
  const { dispatch } = useContext(AppContext);
  const [inspectingTierGift, setInspectingTierGift] = useState(null);
  const { tiers, id, scopedPriceTiers } = campaign;

  const handleTierAdd = () => dispatch({ type: "addTier", payload: { id } });

  const handleTierRemoval = ({ tierId }) =>
    dispatch({ type: "removeTier", payload: { id, tierId } });

  const handleTierReset = ({ tierId }) =>
    dispatch({ type: "resetTier", payload: { id, tierId } });

  const handleMinSpendChange = ({ tierId, price }) =>
    dispatch({ type: "updateTierMinSpend", payload: { id, tierId, price } });

  const handleMaxSpendChange = ({ tierId, price }) =>
    dispatch({ type: "updateTierMaxSpend", payload: { id, tierId, price } });

  const handleGiftSelection = ({ tierId, product }) => {
    dispatch({
      type: "updateTierGiftAssignment",
      payload: { id, tierId, product },
    });
    handleSelectionClose();
  };

  const handleScopedPriceTiersChange = () => {
    dispatch({ type: "toggleScopedPriceTiers", payload: { id } });
  };

  const handleGiftReset = handleGiftSelection;

  const onGiftInspect = ({ tier, tierIndex }) =>
    setInspectingTierGift({ ...tier, index: tierIndex });

  const handleSelectionClose = () => setInspectingTierGift(null);

  return (
    <>
      <>
        {tiers.map((tier, i) => (
          <PriceTierField
            campaign={campaign}
            key={`PriceTierField-${i}`}
            tier={tier}
            tierIndex={i}
            onTierRemoval={handleTierRemoval}
            onTierReset={handleTierReset}
            onMinSpendChange={handleMinSpendChange}
            onMaxSpendChange={handleMaxSpendChange}
            onGiftInspect={onGiftInspect}
          />
        ))}
        <button className="campaign-priceTier-add btn" onClick={handleTierAdd}>
          <PlusIcon />
          <span>Add Tier</span>
        </button>
        <span className="campaign-scopedPriceTiers">
          <label htmlFor={`scopedPriceTiers-${id}`}>
            Gift only highest price tier?
          </label>
          <input
            type="checkbox"
            id={`scopedPriceTiers-${id}`}
            checked={scopedPriceTiers || false}
            onChange={handleScopedPriceTiersChange}
          />
        </span>
      </>

      <>
        {inspectingTierGift ? (
          <ProductSelectionPopup
            campaign={campaign}
            tier={inspectingTierGift}
            onGiftSelection={handleGiftSelection}
            onSelectionClose={handleSelectionClose}
            onGiftReset={handleGiftReset}
          />
        ) : null}
      </>
    </>
  );
}
