import CheckForSave from "./CheckForSave";
// import SetActiveAlert from "./SetActiveAlert";

export default function AddSelectedAsFreeGift(state, payload){
    let { selectedProducts, targetedCampaign: campaignID, campaigns } = state;
    let campaign = campaigns.index[campaignID];
    let { customerGets } = campaign;
    /** @type {ShopifyProduct[]} */
    const productList = Object.values(selectedProducts);
  
    // let notAdded = [];
  
    productList.forEach((product) => {
      const alreadyAdded = customerGets.find((item) => item.id === product.id);
      // TODO: Fix client side
      // !Has incompatability when running concurrent campaigns that share item
      // const isQualifiedBuy = customerBuys.find((item) => item.id === product.id);
      // if (isQualifiedBuy) notAdded.push(product);
      if(!alreadyAdded) {
        delete product.body_html;
        customerGets = customerGets ? [...customerGets, product] : [product];
      }
    });
  
    // if(notAdded.length){
    //     const numNotAdded = notAdded.length
    //     const soleItem = numNotAdded === 1
    //     const message = soleItem 
    //     ? `A selected product was not added as a free gift because it is currently set to be a qualifying buy`
    //     :`${numNotAdded} products were not added as free gifts because they are currently set to be qualifying buys`
    //     state = SetActiveAlert(state, {message})
    // }
  
    campaign = { ...campaign, customerGets };
  
    state.campaigns.index[campaignID] = campaign;
  
    selectedProducts = {};
  
    return CheckForSave({ ...state, selectedProducts });
}