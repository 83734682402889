import CheckForSave from "./CheckForSave";
import ResetTierFromCampaign from "./ResetTierOfCampaign";

export default function RemoveTierFromCampaign(state, payload) {
  const { campaigns } = state;
  const { id: campaignID, tierId } = payload;
  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];

  state = ResetTierFromCampaign(state, { id: campaignID, tierId });
  campaign.tiers = campaign.tiers.filter((tier) => tier.id !== tierId);

  campaigns.index[campaignID] = campaign;
  return CheckForSave({ ...state, campaigns });
}
