import CheckForSave from "./CheckForSave";

export default function ToggleScopedPriceTiers(state, payload) {
  const { campaigns } = state;
  const { id: campaignID } = payload;

  /** @type {GWPCampaign} */
  let campaign = campaigns.index[campaignID];
  campaign.scopedPriceTiers = !campaign.scopedPriceTiers 
  campaigns.index[campaignID] = campaign;
  return CheckForSave({ ...state, campaigns });
}
