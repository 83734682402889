import CheckForSave from "./CheckForSave";
// import SetActiveAlert from "./SetActiveAlert";

export default function AddSelectedAsQualifyingBuy(state, payload) {
  let { selectedProducts, targetedCampaign: campaignID, campaigns } = state;
  let campaign = campaigns.index[campaignID];
  let { customerBuys } = campaign;
  
  /** @type {ShopifyProduct[]} */
  const productList = Object.values(selectedProducts);
  if(!productList.length) return state

  // let notAdded = [];

  productList.forEach((product) => {
    const alreadyAdded = customerBuys.find((item) => item.id === product.id);
    // TODO: Fix client side
    // !Has incompatability when running concurrent campaigns that share item
    // const isFreeGift = customerGets.find((item) => item.id === product.id);
    // if (isFreeGift) notAdded.push(product);
    if(!alreadyAdded) {
      delete product.body_html;
      customerBuys = customerBuys ? [...customerBuys, product] : [product];
    }
  });

  // if(notAdded.length){
  //     const numNotAdded = notAdded.length
  //     const soleItem = numNotAdded === 1
  //     const message = soleItem 
  //     ? `A product was not added as a qualifying buy because it is currently set to be a free gift`
  //     : `${numNotAdded} products were not added as qualifying buys because they are currently set to be free gift`
      
  //     state = SetActiveAlert(state, {message})
  // }

  campaign = { ...campaign, customerBuys };

  state.campaigns.index[campaignID] = campaign;

  selectedProducts = {};

  return CheckForSave({ ...state, selectedProducts });
}
