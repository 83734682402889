import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function RemoveBuyFromCollection(state, payload) {
  const { campaigns } = state;
  const { collection, campaignID } = payload;
  let campaign = campaigns.index[campaignID];
  let { customerBuysFromCollection } = campaign;

  // Field may not be defined on older campaign objects
  customerBuysFromCollection = customerBuysFromCollection
    ? customerBuysFromCollection
    : [];

  const targetCollection = customerBuysFromCollection.find(
    (item) => item.id === collection.id
  );

  const IsTargetCollection = (item) => item === targetCollection;
  const targetIndex = customerBuysFromCollection.findIndex(IsTargetCollection);

  if (!targetCollection) {
    const message = "This product was not found as a [QUALIFYING BUY COLLECTION]"
    return SetActiveAlert(state, {message})
  }

  customerBuysFromCollection.splice(targetIndex, 1);

  campaign = { ...campaign, customerBuysFromCollection };

  state.campaigns = campaigns;

  return CheckForSave({ ...state });
}
