import React, { useContext, useState } from "react";
import { AppContext } from "../../lib";
import CollectionIndexItem from "./CollectionIndexItem";
import Loader from "../layout/Loader";
import "./CollectionIndex.scss";

export default function CollectionIndex() {
  const { state } = useContext(AppContext);
  const { collections } = state;
  const [search, setSearch] = useState("");
  const filteredList = collections
    ? Object.values(collections.index).filter(
        (collection) =>
          collection.title &&
          collection.title.toLowerCase().includes(search.toLowerCase())
      )
    : [];
  const handleSearchInputChange = ({ target: { value } }) => setSearch(value);

  return (
    <div>
      <input
        className="collection-index-search"
        type="text"
        placeholder="Search"
        value={search}
        onChange={handleSearchInputChange}
      />
    <main className="collection-index-list">
      {collections ? (
        filteredList.map((collection) => (
          <CollectionIndexItem
            key={`collection-${collection.id}`}
            collection={collection}
          />
        ))
      ) : (
        <Loader/>
      )}
    </main>
    </div>
  );
}
