import React from "react";

export default function LogsIcon() {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100px"
        width="100px"
        fill="currentColor"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
      >
        <g>
          <path d="M83.583,6.417v87.165H16.417V25.587l19.17-19.17H83.583 M85,5H35L15,25v70h70V5L85,5z" />
        </g>
        <g>
          <line
            x1="25"
            y1="30"
            x2="75"
            y2="30"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.4173"
            strokeMiterlimit="10"
          />
        </g>
        <line
          x1="25"
          y1="40"
          x2="75"
          y2="40"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.4173"
          strokeMiterlimit="10"
        />
        <line
          x1="25"
          y1="50"
          x2="75"
          y2="50"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.4173"
          strokeMiterlimit="10"
        />
        <g>
          <path d="M27.25,79.302h3.823v3.586H27.25V79.302z" />
          <path d="M33.472,70.003h3.981v9.712h6.214v3.173H33.472V70.003z" />
          <path d="M44.669,76.454c0-2.104,0.586-3.741,1.758-4.913s2.804-1.758,4.895-1.758c2.145,0,3.797,0.575,4.957,1.728   c1.16,1.15,1.74,2.764,1.74,4.838c0,1.506-0.254,2.741-0.76,3.705c-0.508,0.964-1.24,1.713-2.197,2.25   c-0.959,0.535-2.152,0.804-3.582,0.804c-1.453,0-2.655-0.231-3.607-0.694s-1.724-1.195-2.316-2.197S44.669,77.96,44.669,76.454z    M48.651,76.472c0,1.301,0.242,2.235,0.725,2.804s1.141,0.853,1.974,0.853c0.855,0,1.518-0.278,1.986-0.835   s0.703-1.556,0.703-2.997c0-1.213-0.246-2.099-0.734-2.658c-0.49-0.56-1.152-0.84-1.99-0.84c-0.803,0-1.448,0.284-1.934,0.853   S48.651,75.159,48.651,76.472z" />
          <path d="M66.643,78.221V75.54h6.152v5.493c-1.178,0.803-2.22,1.349-3.124,1.64c-0.906,0.289-1.979,0.435-3.222,0.435   c-1.529,0-2.776-0.261-3.739-0.782c-0.965-0.521-1.712-1.298-2.242-2.329s-0.795-2.215-0.795-3.551c0-1.406,0.29-2.629,0.87-3.669   s1.43-1.83,2.549-2.369c0.873-0.416,2.048-0.624,3.524-0.624c1.424,0,2.488,0.129,3.195,0.387c0.705,0.258,1.291,0.658,1.758,1.2   c0.465,0.542,0.815,1.229,1.05,2.061l-3.841,0.686C68.62,73.63,68.353,73.258,67.974,73c-0.377-0.258-0.859-0.387-1.445-0.387   c-0.873,0-1.568,0.304-2.088,0.909c-0.518,0.607-0.777,1.566-0.777,2.879c0,1.395,0.262,2.391,0.787,2.988   c0.523,0.598,1.255,0.896,2.192,0.896c0.445,0,0.87-0.064,1.274-0.193s0.867-0.349,1.389-0.659v-1.213H66.643z" />
        </g>
        <line
          x1="25"
          y1="60"
          x2="55"
          y2="60"
          fill="none"
          stroke="currentColor"
          strokeWidth="1.4173"
          strokeMiterlimit="10"
        />
      </svg>
  );
}
