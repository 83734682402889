import CheckForSave from "./CheckForSave";
import SetActiveAlert from "./SetActiveAlert";

export default function (state, payload) {
  const { product, campaignID } = payload;

  const { campaigns } = state;
  let campaign = campaigns.index[campaignID];
  let { customerGets } = campaign;

  const targetProduct = customerGets.find((item) => item.id === product.id);
  if (!targetProduct) {
    const message = "This product was not found as a [QUALIFYING BUY] product"
    return SetActiveAlert(state, {message})
  }

  const targetIndex = customerGets.findIndex((item) => item === targetProduct);

  customerGets.splice(targetIndex, 1);

  campaign = { ...campaign, customerGets };

  state.campaigns = campaigns;
  return CheckForSave({ ...state });
}
