import React, { useContext, useEffect } from "react";
import "./IndexViewport.scss";
import { AppContext } from "../../lib";
import { GetAllCollections, GetAllProducts } from "../../lib/shopifyAPI";
import ProductIndex from "../ProductIndex";
import ViewSelector from "./ViewSelector";
import AddAsAction from "./AddAsAction";
import CollectionIndex from "../CollectionIndex";
import SpecialConditionsIndex from "../SpecialConditionsIndex";

export default function IndexViewport() {
  const { state, dispatch } = useContext(AppContext);
  const { currentView, accessToken } = state;

  useEffect(() => {
    (async () => {
      const products = await GetAllProducts(accessToken);
      dispatch({ type: "hydrateProducts", payload: { products } });

      const collections = await GetAllCollections(accessToken);
      dispatch({ type: "hydrateCollections", payload: { collections } });
    })();
  }, [dispatch, accessToken]);

  return (
    <div className="index-viewer">
      <h2 className="index-viewer-header">ALL {currentView.toUpperCase()}</h2>
      <div className="index-viewer-toolbar">
        <ViewSelector />
        <AddAsAction />
        <div>
          <p className="index-viewer-text">
            <u>Notes:</u>
          </p>
          <ul>
            <li className="index-viewer-text">
              - Select tile(s) below to unlock actions to add as a "Gift" or
              "Qualifying Buy"
            </li>
          </ul>
        </div>
      </div>
      <CurrentView currentView={currentView} />
    </div>
  );
}

const CurrentView = ({ currentView }) => {
  switch (currentView) {
    case "products":
      return <ProductIndex />;
    case "collections":
      return <CollectionIndex />;
    case "special conditions":
      return <SpecialConditionsIndex />;

    default:
      return null;
  }
};
