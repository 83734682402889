import React, { useContext } from "react";
import { CheckIcon } from "../../assets/svgs";
import { AppContext } from "../../lib";
import './ProductIndexItem.scss'

export default function ProductIndexItem({ product }) {
  const { state, dispatch } = useContext(AppContext);
  const {selectedProducts, targetedCampaign} = state
  const selected = selectedProducts[product.id]
  const selectedClass = selected ? 'selected' : ''
  const handleProductClick = () => {
    !selected 
      ? dispatch({ type: "selectProduct", payload: { product } })
      : dispatch({ type: "unselectProduct", payload: { product } });
  };

  return (
    <div
      className="product-index-item"
      id={product.id}
    >
      <div>
        <p className="product-index-item-id">Product ID: {product.id}</p>
        <p className="product-index-item-title">{product.title}</p>
        <p className="product-index-item-inventory">
          Qty: {product.variants[0].inventory_quantity}
        </p>
      </div>
      <div className="product-index-item-selectBox" onClick={handleProductClick}>

      {targetedCampaign ? (
          <span
            className={`product-index-item-selectBox-circle ${selectedClass}`}
          >
            {selected ? <CheckIcon /> : null}
          </span>
        ) : null}
      </div>
    </div>
  );
}
